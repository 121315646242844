import becomePartnerImage from './become_partner.png';
import standOutCompetetor from './stand_out_competetor.png';
import partnershipImage from './parntnership.png';
import dealImage from './deal.png';
import increaseIcon from './increase.png';
import cardsIcon from './cards.png';
import repeatIcon from './repeat.png';
import smartContractIcon from './smart_contract.jpg';
import newCustomerIcon from './new_customer.jpg';
import verifiedDocumentIcon from './verified_document.jpg';
import detailsIcon from './details.jpg';
import getStartedImage from './get_started.png';
import etailerImage from './etailers.png';
import retailerImage from './retailers.png';
import etailerRetailerImage from './etailer-retailer.png';
import getInTouchImage from './get-in-touch.png';
import getStartedBgImage from './get-started.png';
import getInTouchImage1 from './get-in-touch1.png';

export const LandingPageImages = {
  becomePartnerImage,
  standOutCompetetor,
  partnershipImage,
  dealImage,
  increaseIcon,
  cardsIcon,
  repeatIcon,
  smartContractIcon,
  newCustomerIcon,
  verifiedDocumentIcon,
  detailsIcon,
  getStartedImage,
  etailerImage,
  retailerImage,
  etailerRetailerImage,
  getInTouchImage,
  getStartedBgImage,
  getInTouchImage1,
};
