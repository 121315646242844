import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

// @mui
import { Box, Switch, Container, Typography, Stack, CardHeader, Card, CardContent, CardMedia, Button, Grid, CardActionArea, TextField } from '@mui/material';
// _mock_
import { _pricingPlans } from '../_mock/arrays';
// sections
import { PricingPlanCard } from '../sections/pricing';

import video from '../assets/videos/PAAS_introduction.mp4';
import { useState } from 'react';
import { HomeAdvertisement, HomeAdvertisement1, HomeColorPresets, HomeLookingFor1 } from 'src/sections/home';
import { AboutHero, AboutWhat, AboutTeam, AboutVision, AboutTestimonials } from '../sections/about';
import { MotionViewport, varFade, varHover, varTranHover } from 'src/components/animate';
import Image from '../components/image';
import { ComponentCard } from '../sections/_examples';
import javascript from "../assets/logo/js.jpeg";
import golang from "../assets/logo/Go.jpeg";
import kubernetes from "../assets/logo/kubernetes.png";
import aws from "../assets/logo/aws.jpeg";
import couchbase from "../assets/logo/couchbase.jpeg";
import nodejs from "../assets/logo/js.jpeg";
import jaeger from "../assets/logo/Jaeger.jpeg";
import fabric from "../assets/logo/HLF.jpeg";

import react from "../assets/logo/react.jpeg";
import screen1 from "../assets/logo/screen1.jpeg";
import screen2 from "../assets/logo/screen2.jpeg";
import screen3 from "../assets/logo/screen3.jpeg";
import banner1 from "../assets/tiles/banner6.png";
import banner2 from "../assets/tiles/banner7.png";
import Iconify from '../components/iconify';

import Paper from 'src/theme/overrides/Paper';
import { width } from '@mui/system';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Link } from '@mui/material';

export default function IntroductionPage() {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> Introduction | Loyyal Partnerhub</title>
      </Helmet>

      <Container sx={{ pb: 10, minHeight: 1, }} >
        <br />
        <Box gap={12} display="grid">
          <img src={banner2} />
          {/* <CardMedia
            style={{ width: '1400px', marginLeft: '-100px', marginTop: '20px' }}
            component="video"
            image="https://loyyal-assets.s3.me-south-1.amazonaws.com/vapourware/videos/Partner+Hub+5.0.mp4"
            title='title'
            controls
          /> */}
        </Box>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button size="large" variant="contained" onClick={() => navigate('/v1/interactive')}>
            Interactive Portal
          </Button>
          <Button sx={{ mx: 1, }} size="large" variant="contained" onClick={() => navigate('/v1/preview-videos')}>
            30 Sec Preview
          </Button>
        </div>

      </Container>
    </>
  );
}
