// components
import { useTheme } from '@emotion/react';
import Chart, { useChart } from '../../../../components/chart';

// const series = [
//   {
//     name: 'Desktops',
//     data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
//   },
// ];

export default function ChartLine({ type, xAxisLabel, yAxisLabel, categories, series }) {
  const theme = useTheme();
  const chartOptions = useChart({
    xaxis: {
      title: { text: xAxisLabel },
      categories: categories,
    },
    yaxis: {
      title: { text: yAxisLabel },
    },
    tooltip: {
      x: {
        show: false,
      },
      marker: { show: false },
    },
    markers: {
      size: [6]
    },
    stroke: {
      width: 0
    },

    // legend: {
    //   show: true,
    //   fontSize: String(20),
    //   position: 'top',
    //   horizontalAlign: 'right',
    //   markers: {
    //     radius: 12,
    //   },
    //   fontWeight: 800,
    //   itemMargin: { horizontal: 12 },

    // },

  });

  return <Chart type={'line'} series={series} options={chartOptions} height={320} />;
}
