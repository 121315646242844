import { useEffect, useState } from 'react';
// @mui
import { Box, MenuItem, Stack, Typography } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// components
import Image from '../../../components/image';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { jwtDecode } from 'jwt-decode';
import {
  getAllPrograms,
  getAllProgramsAdmin,
  getProgramContacts,
} from 'src/utils/chart_calculation_ver1';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useSessionStorage from 'src/hooks/useSessionStorage';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// ----------------------------------------------------------------------

export default function ProgramPopOver() {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const [openPopover, setOpenPopover] = useState(null);
  const [jwtLocal, setJwtLocal] = useState('');
  const [decodedJwt, setDecodedJwt] = useState({});
  const [programUsingDomain, setProgramUsingDomain] = useState({});
  const [getAllProgramsResponse, setGetAllProgramsResponse] = useState([]);
  const [dropDownProgramCode, setDropDownProgramCode] = useState('ENOC');
  //   const [selectedProgram, setSelectedProgram] = useSessionStorage('dropDownProgramSelect', 'ET');
  const [selectedProgram, setSelectedProgram] = useSessionStorage(
    'dropDownProgramSelect',
    decodedJwt?.programCode ? decodedJwt?.programCode : getAllProgramsResponse[0]?.programCode
  );

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  console.log(selectedProgram, 'selectedProgram=====================================');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');
    const token = localStorage.getItem('jwtTokenLocal');

    // console.log(tokenParam, 'tokenParam---');

    if (tokenParam) {
      //   setUrlJwtToken(tokenParam);
      setJwtLocal(tokenParam);
    } else {
      setJwtLocal(token);
    }
  }, [location.search]);

  //   useEffect(() => {
  //     const token = localStorage.getItem('jwtTokenLocal');

  //     if (token) {
  //       // Token exists, you can use it as needed
  //       //   console.log('Token:', token);
  //       setJwtLocal(token);
  //     } else {
  //       // Token does not exist in local storage
  //       console.log('Token not found');
  //     }

  //     //   const decoded = jwtDecode(token);

  //     //   console.log(decoded, 'decoded---------');
  //   }, []);

  useEffect(() => {
    if (jwtLocal) {
      const decoded = jwtDecode(jwtLocal);
      setDecodedJwt(decoded);
    }

    //   console.log(decoded, 'decoded---------');
  }, [jwtLocal]);

  // console.log(
  //   sessionStorage.getItem('dropDownProgramSelect'),
  //   "useSessionStorage('dropDownProgramSelect', '')"
  // );

  // useEffect(() => {
  //   if (getAllProgramsResponse.length > 0 && !sessionStorage.getItem('dropDownProgramSelect')) {
  //     let data = decodedJwt?.programCode
  //       ? decodedJwt?.programCode
  //       : getAllProgramsResponse[0]?.programCode;
  //     setSelectedProgram(data);
  //   }
  // }, [getAllProgramsResponse]);

  //   console.log(decodedJwt, 'decodedJwt****------------------------');

  useEffect(() => {
    // getProgramContacts
    if (Object.keys(decodedJwt).length !== 0) {
      //   getProgramUsingDomain();
      fetchAllPrograms(decodedJwt?.programId);
    }
  }, [decodedJwt]);

  //   useEffect(() => {
  //     sessionStorage.setItem('dropDownProgramSelect', dropDownProgramCode);
  //   }, [dropDownProgramCode]);

  const getProgramUsingDomain = async () => {
    const url = window.location.href;
    //   console.log(url.includes('ethiopianairlines'), 'ethiopianairlines');

    var config = {
      method: 'get',
      // url :`${process.env.REACT_APP_CORE_ENGINE_URL}/getProgramUsingDomain?domain=ethiopianairlines.accesspoint.dev.loyyal.io`,
      url: `${process.env.REACT_APP_CORE_ENGINE_URL}/program/getProgramUsingDomain`,
      headers: {
        // Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTM5NTI0MDEsImlzcyI6ImNvcmUtZW5naW5lLXNlcnZpY2UiLCJzdWIiOiJhYW1pci5tb2hkQGxveXlhbC5jb20iLCJhdWQiOlsiY29yZS1lbmdpbmUtc2VydmljZSIsImNvbnRyYWN0LW1hbmFnZW1lbnQtc2VydmljZSIsImFsbC1sb3l5YWwtZnJvbnRlbmRzIl0sImF1dGhvcml6ZWQiOnRydWUsIm5hbWUiOiJBYW1pciIsInVzZXJuYW1lIjoiYWFtaXIubW9oZEBsb3l5YWwuY29tIiwicm9sZUNvZGUiOiJMT1lZQUxfQURNSU4iLCJleHAiOjE3NDU0ODg0MDF9.BQ35nGRf2xt6YUokI6DUoZ0RmnyJPZLb4Wn2CGCu1PfQx9tFN0OtHM4cW4IQxlEfzzGE9bl3V8rNImpqVJS9uhEDF5Jey2NvJHUFUoW03rsOEHxKSyu9whMQ9ez92isqbb-T5x_ovKFjSINiETm5Kalr_u8QDaC_904enxWrIRrDECYtuBIZvN67DxoaryhEDjpaLQ_tQ1GD1P6IuFZtkaPzv1skxbv72M1r1C8_b870qwrtI2iJXIai8RvtNvyuEiU-yGMyjMDsg7lKTzl5IKM72WiVT4h5qvy3_kjnIt4133Rr-i0PcZHwpZ4LPsIWvV3gz5qTwpaO17YCKzZVIg`,
        Authorization: `Bearer ${jwtLocal}`,
      },
      // params: {
      //   domain: 'enoc.accesspoint.dev.loyyal.io',
      // },
      params: {
        domain:
          decodedJwt.programCode === 'ET'
            ? 'ethiopianairlines.accesspoint.dev.loyyal.io'
            : decodedJwt.programCode === 'ENOC'
            ? 'enoc.accesspoint.dev.loyyal.io'
            : 'ethiopianairlines.accesspoint.dev.loyyal.io',
      },
    };
    const response = await axios(config);
    // console.log(response, 'response0000');
    setProgramUsingDomain(response);

    // console.log(process.env.REACT_APP_CORE_ENGINE_URL,"env")
  };

  //   const response = await getDefaultContractThreshold(decodedJwt);

  const fetchAllPrograms = async (programId) => {
    if (Object.keys(decodedJwt).length !== 0) {
      if (decodedJwt?.roleCode === 'PROGRAM_ADMIN' && decodedJwt.hasOwnProperty('programId')) {
        const response = await getAllPrograms(programId);
        setGetAllProgramsResponse(response);
        console.log(response, 'get all programs');
      }

      if (decodedJwt?.roleCode === 'LOYYAL_ADMIN') {
        const response = await getAllProgramsAdmin();
        setGetAllProgramsResponse(response);
      }
    }
  };

  const handleChangeProgram = (programCode) => {
    setDropDownProgramCode(programCode);
    setSelectedProgram(programCode);
    window.location.reload();
    handleClosePopover();
  };

  //   console.log(decodedJwt, 'decodedJwt--------');
  //   //   console.log(dropDownProgramCode, 'dropDownProgramCode');
  //   console.log(getAllProgramsResponse, 'getAllProgramsResponse');

  return (
    <>
      {/* <IconButtonAnimate
        // onClick={handleOpenPopover}
        // disabled
        sx={{
          width: 60,
          height: 60,
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      >
       
        <Image
          disabledEffect
          //   src={'https://accesspoint.dev.loyyal.io/assets/logo/merchant-web-page/enoc-logo.svg'}
          src={
            decodedJwt.programCode === 'ET'
              ? 'https://core-engine.dev.loyyal.net/program-logos/afbc83a4-60a8-45dc-91d2-b7f86f50cf0c.jpg'
              : decodedJwt.programCode === 'ENOC'
              ? 'https://accesspoint.dev.loyyal.io/assets/logo/merchant-web-page/enoc-logo.svg'
              : 'https://accesspoint.dev.loyyal.io/assets/logo/merchant-web-page/enoc-logo.svg'
          }
          alt={currentLang.label}
          style={{ width: '100%', height: '100%' }}
        />
      </IconButtonAnimate> */}

      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          marginRight: '10px',
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        {selectedProgram ? (
          <Typography style={{ fontWeight: 'bold' }}>{selectedProgram}</Typography>
        ) : (
          // <Image
          //   disabledEffect
          //   // src={
          //   //   decodedJwt.programCode === 'ET'
          //   //     ? 'https://core-engine.dev.loyyal.net/program-logos/afbc83a4-60a8-45dc-91d2-b7f86f50cf0c.jpg'
          //   //     : decodedJwt.programCode === 'ENOC'
          //   //     ? 'https://accesspoint.dev.loyyal.io/assets/logo/merchant-web-page/enoc-logo.svg'
          //   //     : 'https://accesspoint.dev.loyyal.io/assets/logo/merchant-web-page/enoc-logo.svg'
          //   // }
          //   src={'https://accesspoint.dev.loyyal.io/assets/logo/merchant-web-page/enoc-logo.svg'}
          //   alt={currentLang.label}
          //   style={{ width: '50px', height: '50px' }}
          //   // style={{ width: '100%', height: '100%' }}
          // />
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>Select</Typography>
            <ArrowDropDownIcon />
          </Box>
        )}
      </IconButtonAnimate>
      {/* <Typography style={{ color: 'black' }}>{selectedProgram}</Typography> */}
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 180, height: '30%', overflowY: 'scroll' }}
      >
        <Stack spacing={0.75}>
          {getAllProgramsResponse.map((option) => (
            <MenuItem
              key={option.id}
              selected={option?.programCode === selectedProgram}
              //   selected={option.programCode}
              onClick={() => handleChangeProgram(option?.programCode)}
            >
              <Image
                disabledEffect
                alt={option?.programCode}
                // src={option.icon}
                // src={`https://accesspoint.dev.loyyal.io${option?.programLogoUri}`}
                src={`${process.env.REACT_APP_CORE_ENGINE_URL}${option?.programLogoUri}`}
                sx={{ width: 28, mr: 2 }}
              />

              {option?.programCode}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
