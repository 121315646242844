import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import { Image } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from '../../components/snackbar';
import axios from 'axios';
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, programDetails, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // ----------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );
  const url = window.location.href;
  const location = useLocation();
  const currentUrl = location.pathname;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // const [programDetails, setProgramDetails] = useState(null);

  const urlObject = new URL(url);
  console.log(urlObject.hostname, 'urlObject---------');

  const getProgramUsingDomain = async () => {
    // const url = window.location.href;

    try {
      var config = {
        method: 'get',

        url: `${process.env.REACT_APP_CORE_ENGINE_URL}/program/getProgramUsingDomain`,
        headers: {
          Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTM5NTI0MDEsImlzcyI6ImNvcmUtZW5naW5lLXNlcnZpY2UiLCJzdWIiOiJhYW1pci5tb2hkQGxveXlhbC5jb20iLCJhdWQiOlsiY29yZS1lbmdpbmUtc2VydmljZSIsImNvbnRyYWN0LW1hbmFnZW1lbnQtc2VydmljZSIsImFsbC1sb3l5YWwtZnJvbnRlbmRzIl0sImF1dGhvcml6ZWQiOnRydWUsIm5hbWUiOiJBYW1pciIsInVzZXJuYW1lIjoiYWFtaXIubW9oZEBsb3l5YWwuY29tIiwicm9sZUNvZGUiOiJMT1lZQUxfQURNSU4iLCJleHAiOjE3NDU0ODg0MDF9.BQ35nGRf2xt6YUokI6DUoZ0RmnyJPZLb4Wn2CGCu1PfQx9tFN0OtHM4cW4IQxlEfzzGE9bl3V8rNImpqVJS9uhEDF5Jey2NvJHUFUoW03rsOEHxKSyu9whMQ9ez92isqbb-T5x_ovKFjSINiETm5Kalr_u8QDaC_904enxWrIRrDECYtuBIZvN67DxoaryhEDjpaLQ_tQ1GD1P6IuFZtkaPzv1skxbv72M1r1C8_b870qwrtI2iJXIai8RvtNvyuEiU-yGMyjMDsg7lKTzl5IKM72WiVT4h5qvy3_kjnIt4133Rr-i0PcZHwpZ4LPsIWvV3gz5qTwpaO17YCKzZVIg`,
        },
        // params: {
        //   domain: 'enoc.accesspoint.dev.loyyal.io',
        // },
        params: {
          // domain: 'enoc.accesspoint.dev.loyyal.io',
          domain: urlObject.hostname,
        },
      };

      const response = await axios(config);
      // setProgramDetails(response?.data);
      console.log(response?.data);
    } catch (error) {
      enqueueSnackbar('No Program details found', {
        variant: 'error',
      });
    }
  };

  // useEffect(() => {
  //   getProgramUsingDomain();
  // }, []);

  console.log(programDetails, 'programDetails----------------------------');

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 100,
        height: 100,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {/* <img src="/logo/loyyal-logo.svg" /> */}

      {/* {url.includes('ethiopianairlines') ? (
        <img src="https://core-engine.dev.loyyal.net/program-logos/afbc83a4-60a8-45dc-91d2-b7f86f50cf0c.jpg" />
      ) : url.includes('enoc') ? (
        <img src="https://accesspoint.dev.loyyal.io/assets/logo/merchant-web-page/enoc-logo.svg" />
      ) : (
        <img src="" alt="" />
      )} */}

      {programDetails?.programLogoUri && (
        <img
          style={{
            width: '100px',
            height: '100px',
            objectFit: 'contain',
            borderRadius: '10px',
            marginLeft: '10px',
          }}
          // src={`https://core-engine.dev.loyyal.net/program-logos/a1f94bbd-ce2c-404c-8abc-78f6654c20b0.png`}
          src={`${process.env.REACT_APP_CORE_ENGINE_URL}${programDetails?.programLogoUri}`}
        />
        // <img src={`${process.env.REACT_APP_CORE_ENGINE_URL}${programDetails?.programLogoUri}`} />
      )}
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Box>{logo}</Box>
    // <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
    //   {logo}
    // </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
