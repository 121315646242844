import { ComponentCard } from '../_examples';
import javascript from "../../assets/logo/js.jpeg";
import golang from "../../assets/logo/Go.jpeg";
import kubernetes from "../../assets/logo/kubernetes.png";
import aws from "../../assets/logo/aws.jpeg";
import couchbase from "../../assets/logo/couchbase.jpeg";
import nodejs from "../../assets/logo/js.jpeg";
import jaeger from "../../assets/logo/Jaeger.jpeg";
import fabric from "../../assets/logo/HLF.jpeg";
import react from "../../assets/logo/react.jpeg";


import { m } from 'framer-motion';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Container, Typography, Stack, Grid } from '@mui/material';
// components
import Image from '../../components/image';
import { MotionViewport, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

// ----------------------------------------------------------------------

export default function HomeCleanInterfaces() {
  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        <Description />
        <Content />
      </Container>
    </StyledRoot>
  );
}

// ----------------------------------------------------------------------

function Description() {
  return (
    <Stack
      spacing={3}
      sx={{
        maxWidth: 520,
        mx: 'auto',
        zIndex: { md: 99 },
        // position: { md: 'absolute' },
        textAlign: { xs: 'center', md: 'left' },
      }}
    >
      <m.div variants={varFade().inUp}>
        <Typography
          variant="h2"
          sx={{
            textShadow: (theme) =>
              theme.palette.mode === 'light'
                ? 'unset'
                : `4px 4px 16px ${alpha(theme.palette.grey[800], 0.48)}`,
          }}
        >
          Technology Stack
        </Typography>
      </m.div>
    </Stack>
  );
}

// ----------------------------------------------------------------------

function Content() {
  return (
    <Box sx={{ position: 'relative' }}>
      <m.div variants={varFade().inUp}>
        <Grid container alignItems="center" spacing={{ xs: 1, md: 0 }} >
          <Grid item xs={6} md={2}>
            <m.div style={{ margin: '10px' }}>
              <ComponentCard key="reactjs" item={{ name: "ReactJS", icon: react, href: "" }} />
            </m.div>
          </Grid>
          <Grid item xs={6} md={2}>
            <m.div style={{ margin: '10px' }}>
              <ComponentCard key="reactjs" item={{ name: "Javascript", icon: javascript, href: "" }} />
            </m.div>
          </Grid>
          <Grid item xs={6} md={2}>
            <m.div style={{ margin: '10px' }}>
              <ComponentCard key="reactjs" item={{ name: "Golang", icon: golang, href: "" }} />
            </m.div>
          </Grid>
          <Grid item xs={6} md={2}>
            <m.div style={{ margin: '10px' }}>
              <ComponentCard key="reactjs" item={{ name: "Jaeger", icon: jaeger, href: "" }} />
            </m.div>
          </Grid>
          <Grid item xs={6} md={2}>
            <m.div style={{ margin: '10px' }}>
              <ComponentCard key="reactjs" item={{ name: "Couchbase", icon: couchbase, href: "" }} />
            </m.div>
          </Grid>
          <Grid item xs={6} md={2}>
            <m.div style={{ margin: '10px' }}>
              <ComponentCard key="reactjs" item={{ name: "kubernetes", icon: kubernetes, href: "" }} />
            </m.div>
          </Grid>
          <Grid item xs={6} md={2}>
            <m.div style={{ margin: '10px' }}>
              <ComponentCard key="reactjs" item={{ name: "Hyperledger Fabric", icon: fabric, href: "" }} />
            </m.div>
          </Grid>
          <Grid item xs={6} md={2}>
            <m.div style={{ margin: '10px' }}>
              <ComponentCard key="reactjs" item={{ name: "AWS", icon: aws, href: "" }} />
            </m.div>
          </Grid>
        </Grid>
      </m.div>
    </Box>
  );
}
