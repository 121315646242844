import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button, Typography } from '@mui/material';
import { NdaPageImages } from 'src/assets/images';
import { Link, useNavigate } from 'react-router-dom';
import { OnboardingForm, OnboardingHero, OnboardingList } from '../sections/partner-onboarding';

const steps = [
  'NDA',
  'Onboarding details',
  'Partnership details',
  'Calculate projected volume',
  'Calculate target price',
  'contract summary',
];

export const PartnerOnboardingDetailPage = () => {
  const navigate = useNavigate();
 
  
  return (
    <>
      

     <OnboardingForm />


    </>
  );
};