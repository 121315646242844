import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button, Typography } from '@mui/material';
import { NdaPageImages } from 'src/assets/images';
import { Link, useNavigate } from 'react-router-dom';
import { OnboardingForm, OnboardingHero, OnboardingList } from '../sections/partner-onboarding';
import useSessionStorage from 'src/hooks/useSessionStorage';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useSnackbar } from '../components/snackbar';

const steps = [
  'NDA',
  'Onboarding details',
  'Partnership details',
  'Calculate projected volume',
  'Calculate target price',
  'contract summary',
];

export const PartnerOnboardingPageV3 = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isNDAChecked, setNDAChecked] = useState(false);
  const [decodedJwt, setDecodedJwt] = useState({});
  const [selectedProgram, setSelectedProgram] = useSessionStorage('dropDownProgramSelect', 'ET');
  const [ndaDoc, setNdaDoc] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('jwtTokenLocal');

    if (token) {
      const decoded = jwtDecode(token);
      setDecodedJwt(decoded);
    }
  }, []);

  useEffect(() => {
    if (
      Object.keys(decodedJwt)?.length > 0 ||
      JSON.parse(localStorage.getItem('programDetails'))?.programCode
    ) {
      fetchNdaDoc();
    }
  }, [decodedJwt]);

  const fetchNdaDoc = async () => {
    try {
      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_PARTNERHUB_SELF_SERVICE_URL}/v1/dashboard/configuration/nda`,
        params: {
          namespace: decodedJwt.programCode
            ? `${decodedJwt.programCode}.rules.doc`
            : JSON.parse(localStorage.getItem('programDetails'))?.programCode
            ? `${JSON.parse(localStorage.getItem('programDetails'))?.programCode}.rules.doc`
            : `${selectedProgram}.rules.doc`,
        },
      };

      const response = await axios(config);

      if (response?.data?.body?.ndaPath) {
        setNdaDoc(response?.data?.body?.ndaPath);
      }
    } catch (error) {
      enqueueSnackbar('No NDA Document found', {
        variant: 'error',
      });
    }

    // console.log(response, 'redsponse');
  };

  //   console.log(ndaDoc, 'ndaDoc====');

  useEffect(() => {}, []);

  console.log(JSON.parse(localStorage.getItem('programDetails')), 'pppppppppppppppp');

  return (
    <>
      {/* <Box sx={{ width: '100%' }}> */}
      {/* {!isNDAChecked && <> */}
      <Box sx={{ paddingLeft: '5rem', paddingRight: '5rem', paddingTop: '2rem' }}>
        <Stepper
          sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: '#3464B3', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
              color: '#919EAB', // Just text label (COMPLETED)
            },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#919EAB', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
              color: '#637381', // Just text label (ACTIVE)
            },
          }}
          activeStep={1}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel sx={{ fontSize: '12px' }}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Typography
        variant="h2"
        textAlign={'center'}
        sx={{ marginTop: '2rem' }}
        gutterBottom
        component="div"
      >
        Non-disclosure <span style={{ color: '#3464B3' }}>Agreement</span>
      </Typography>
      <Typography
        variant="body1"
        textAlign={'center'}
        gutterBottom
        component="div"
        sx={{ paddingLeft: '3rem', paddingRight: '3rem' }}
      >
        To start with the partnership agreement, please sign the below mutual NDA. Every partner
        signs <br /> this to ensure the confidentiality is maintained by you and us.
      </Typography>

      <Box
        sx={{
          width: '100%',
          height: '800px',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${NdaPageImages.laptopImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Box sx={{ mb: 12, mr: 1, border: '5px solid' }} width={'65%'} height={'78%'}>
          <iframe
            height={'620'}
            width={'100%'}
            // src="/assets/docs/non-disclousure-agreement.pdf"
            src={ndaDoc}
          ></iframe>
        </Box>
      </Box>
      <Box
        display={'block'}
        sx={{
          backgroundColor: '#3464B3',
          paddingTop: '6vw',
          paddingBottom: '7vw',
          width: '100%',
          position: 'relative',
          top: '-50px',
          zIndex: -9,
        }}
      >
        <Typography
          variant="body1"
          textAlign={'center'}
          color={'#fff'}
          gutterBottom
          component="div"
          sx={{ paddingLeft: '3rem', paddingRight: '3rem' }}
        >
          By moving further, I state that I have read and understood the terms and conditions
        </Typography>

        <Box
          display={'flex'}
          alignContent={'center'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ width: '100%', marginTop: '3rem' }}
          zIndex={100}
        >
          <Button
            onClick={() => navigate(-1)}
            sx={{
              border: '1px solid #fff',
              width: '20%',
              fontSize: '12px',
              marginRight: '1rem',
              color: '#fff',
            }}
          >
            Go Back
          </Button>

          <Button
            //   v1/partner-onboarding-detail
            // href='https://partnerhub.dev.loyyal.io/v1/partner-onboarding'
            onClick={() => {
              navigate('/v1/partner-onboarding-detail');
            }}
            disabled={ndaDoc === ''}
            sx={{ backgroundColor: '#fff', width: '20%', fontSize: '12px', cursor: 'pointer' }}
            // onClick={()=> setNDAChecked(true)}
          >
            I Agree
          </Button>
        </Box>
      </Box>
      {/* </Box> */}
      {/* </>} */}

      {/* {isNDAChecked && <OnboardingForm />} */}
    </>
  );
};
