// components
import Chart, { useChart, useChart1 } from '../../../../components/chart';
import { useTheme, alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------



export default function ChartColumnSingle1({ title, unit, categories, series, xAxisLabel, yAxisLabel, height }) {
  const theme = useTheme();

  const chartOptions = useChart1({
    plotOptions: {
      bar: {
        columnWidth: '30%',
      },
    },
    fill: {
      type: ['gradiant', 'gradiant', 'gradiant', 'gradiant', 'gradiant', 'gradiant'],
    },
    stroke: {
      show: false,
    },
    xaxis: {
      categories: categories,
      title: { text: xAxisLabel },
    },
    yaxis: {
      title: { text: yAxisLabel },
    },
    tooltip: {
      y: {
        formatter: (value) => `${value} ${unit}`,
      },
    },
  }, [
    theme.palette.primary.main,
    '#FEC8D8',
    "#FFB302",
    "#FFC86D",
    "#FBDBA2",
    "#F6EADB"

    // aff49a

    // option 2
    // '#86CBFA',
    // "#87451F",
    // "#F2E599",
    // // "#FBF2EA",
    // "#f2c5db",
    // "#fff"

    //option 1
    // '#99D388',
    // "#ECD969",
    // "#F2E599",
    // // "#FBF2EA",
    // "#ecadda",
    // "#fff"


    // '#11c08563',
    // "#A52A2A",
    // "#964B00",
    // "#fff",
    // "#fff",

    // "#ffffff"
  ]);

  return <Chart type="area" series={series} options={chartOptions} height={height || 500} />;
}
