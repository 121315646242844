import { m } from 'framer-motion';
import {
  Button,
  Typography,
  TextField,
  Stack,
  Container,
  Grid,
  Stepper,
  StepLabel,
  Box,
  Step,
  Paper,
  StepContent,
  MenuItem,
  CardHeader,
  CardContent,
  Tooltip,
  Card,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getDefaultCompanyConfiguration } from 'src/utils/chart_calculation_ver1';
import { varFade, MotionViewport } from '../../components/animate';
import { ChartColumnSingle1 } from '../_examples/extra/chart';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { TableHeadCustom } from 'src/components/table';
import { init } from 'i18next';

export function OnboardingDetails({ companyDetails, handleChange, setCompanyDetails }) {
  const [marketList, setMarketList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [country, setCountry] = useState([
    { key: 0, name: 'Australia', value: 'Australia', countryCode: '+61' },
    { key: 1, name: 'Canada', value: 'Canada', countryCode: '+1' },
    { key: 2, name: 'China', value: 'China', countryCode: '+86' },
    { key: 3, name: 'Egypt', value: 'Egypt', countryCode: '+20' },
    { key: 20, name: 'Ethiopia', value: 'Ethiopia', countryCode: '+251' },
    { key: 4, name: 'Indonesia', value: 'Indonesia', countryCode: '+62' },
    { key: 5, name: 'Japan', value: 'Japan', countryCode: '+81' },
    { key: 6, name: 'Kenya', value: 'Kenya', countryCode: '+254' },
    { key: 7, name: 'Malaysia', value: 'Malaysia', countryCode: '+60' },
    { key: 8, name: 'New Zealand', value: 'New Zealand', countryCode: '+64' },
    { key: 9, name: 'Philippines', value: 'Philippines', countryCode: '+63' },
    { key: 10, name: 'Saudi Arabia', value: 'Saudi Arabia', countryCode: '+966' },
    { key: 12, name: 'Singapore', value: 'Singapore', countryCode: '+65' },
    { key: 13, name: 'South Africa', value: 'South Africa', countryCode: '+27' },
    { key: 14, name: 'South Korea', value: 'South Korea', countryCode: '+82' },
    { key: 15, name: 'Thailand', value: 'Thailand', countryCode: '+66' },
    { key: 16, name: 'United Arab Emirates', value: 'United Arab Emirates', countryCode: '+971' },
    { key: 17, name: 'United Kingdom', value: 'United Kingdom', countryCode: '+44' },
    { key: 18, name: 'United States', value: 'United States', countryCode: '+1' },
    { key: 19, name: 'Vietnam', value: 'Vietnam', countryCode: '+84' },
  ]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await getDefaultCompanyConfiguration();

    console.log(response, 'response------');

    setMarketList(response.body[0]?.config?.market);
    setCategoryList(response.body[0]?.config?.category);
    setSubCategoryList(response.body[0]?.config?.subcategory);
  };

  // console.log(marketList, 'marketList------');

  return (
    <>
      <Stack style={{ padding: '40px' }} component={MotionViewport} spacing={3}>
        <Typography variant="h4" style={{ textAlign: 'left' }}>
          Company Details
        </Typography>
        {/* <Grid container spacing={3}> */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <m.div>
              <TextField
                size="small"
                fullWidth
                label="Company Name*"
                value={companyDetails.companyName}
                id="companyName"
                onChange={(e) => handleChange(e, 'companyDetails', 'companyName')}
              />
              {/* {!companyDetails.companyName && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  This is required field
                </Typography>
              )} */}
            </m.div>
          </Grid>
          <Grid item xs={12} md={4}>
            <m.div>
              <TextField
                size="small"
                fullWidth
                label="Contact Name*"
                value={companyDetails.contactName}
                id="contactName"
                onChange={(e) => handleChange(e, 'companyDetails', 'contactName')}
              />
              {/* {!companyDetails.contactName && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  This is required field
                </Typography>
              )} */}
            </m.div>
          </Grid>
          <Grid item xs={12} md={4}>
            <m.div>
              <TextField
                size="small"
                fullWidth
                label="Contact Email address*"
                value={companyDetails.contactEmail}
                id="contactEmail"
                onChange={(e) => handleChange(e, 'companyDetails', 'contactEmail')}
              />
              {/* {!companyDetails.contactEmail && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  This is required field
                </Typography>
              )} */}
            </m.div>
          </Grid>
          {/* ---------------------------------------------------- */}
          <Grid item xs={12} md={2}>
            <m.div>
              <TextField
                size="small"
                fullWidth
                label="Country Code*"
                disabled
                value={companyDetails.countryCode}
                //  id="contactPhoneNo"
                id="countryCode"
                onChange={(e) => handleChange(e, 'companyDetails', 'countryCode')}
                sx={{
                  '& input': {
                    padding: '8.5px 6px',
                  },
                }}
              />
              {/* {!companyDetails.countryCode && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  This is required field
                </Typography>
              )} */}
            </m.div>
          </Grid>
          <Grid item xs={12} md={3}>
            <m.div>
              <TextField
                size="small"
                fullWidth
                label="Phone No*"
                type="number"
                value={companyDetails.contactPhoneNo}
                id="contactPhoneNo"
                onChange={(e) => handleChange(e, 'companyDetails', 'contactPhoneNo')}
              />
              {/* {!companyDetails.contactPhoneNo && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  This is required field
                </Typography>
              )} */}
            </m.div>
          </Grid>
          {/* ------------------------------------------ */}
          <Grid item xs={12} md={3}>
            <m.div>
              <TextField
                size="small"
                fullWidth
                label="Company Address"
                value={companyDetails.companyAddress}
                id="companyAddress"
                onChange={(e) => handleChange(e, 'companyDetails', 'companyAddress')}
              />
            </m.div>
          </Grid>
          <Grid item xs={12} md={2}>
            <m.div>
              <TextField
                size="small"
                fullWidth
                label="City"
                value={companyDetails.city}
                id="city"
                //  onChange={(e) => handleChange(e, "companyDetails", "companyAddress")}

                onChange={(e) => handleChange(e, 'companyDetails', 'city')}
              />
            </m.div>
          </Grid>
          <Grid item xs={12} md={2}>
            {/* country */}
            <m.div>
              {/* <TextField
                size="small"
                fullWidth
                label="Country"
                // value={companyDetails.companyAddress}
                value={companyDetails.country}
                id="country"
                onChange={(e) => handleChange(e, 'companyDetails', 'country')}
              /> */}
              <TextField
                size="small"
                variant="outlined"
                select
                fullWidth
                label="Country"
                value={companyDetails.country}
                id="country"
                onChange={(e) => {
                  //   console.log(e, 'eedrd');
                  let temp = country.find((i) => i.value == e.target.value);
                  //   console.log(temp, 'temp');
                  handleChange(e, 'companyDetails', 'country');
                  setCompanyDetails((prevDetails) => ({
                    ...prevDetails,
                    countryCode: temp.countryCode,
                  }));
                }}
              >
                {country &&
                  country.map((item) => (
                    <MenuItem key={item.key} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </TextField>
            </m.div>
          </Grid>
          {/* ----------------------------------------- */}
          <Grid item xs={12} md={3}>
            <m.div>
              <TextField
                size="small"
                fullWidth
                label="Company Website*"
                value={companyDetails.companyWebsite}
                id="companyWebsite"
                onChange={(e) => handleChange(e, 'companyDetails', 'companyWebsite')}
              />
              {/* {!companyDetails.companyWebsite && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  This is required field
                </Typography>
              )} */}
            </m.div>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              variant="outlined"
              select
              fullWidth
              label="Market"
              value={companyDetails.market}
              id="market"
              onChange={(e) => {
                handleChange(e, 'companyDetails', 'market');
                console.log(e, 'eeeeeeeeeeeeee');
              }}
            >
              {/* {marketList &&
                marketList.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))} */}
              {marketList &&
                marketList.map((item) => (
                  <MenuItem key={item.value} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
            </TextField>
            {/* <m.div style={{ background: '#b5b5b5', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Country or region in which the partnership will be made available to collectors.</Typography>
                    </m.div> */}
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              variant="outlined"
              select
              fullWidth
              label="Category"
              value={companyDetails.category}
              id="category"
              onChange={(e) => handleChange(e, 'companyDetails', 'category')}
            >
              {/* {categoryList &&
                categoryList.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
                
                */}

              {categoryList &&
                categoryList.map((item) => (
                  <MenuItem key={item.value} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
            </TextField>
            {/* <m.div style={{ background: '#b5b5b5', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Category which best describes the partner’s main business</Typography>
                    </m.div> */}
          </Grid>
          <Grid item xs={12} md={3}>
            <m.div>
              <TextField
                size="small"
                variant="outlined"
                select
                fullWidth
                label="Sub Category"
                value={companyDetails.category}
                id="subCategory"
                onChange={(e) => handleChange(e, 'companyDetails', 'subCategory')}
              >
                {/* {subCategoryList &&
                  subCategoryList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))} */}
                {subCategoryList &&
                  subCategoryList.map((item) => (
                    <MenuItem key={item.value} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </TextField>
            </m.div>
            {/* <m.div style={{ background: '#b5b5b5', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Sub-category which best describes the partner’s main business</Typography>
                    </m.div> */}
          </Grid>
          <Typography variant="subtitle2" style={{ color: '#f44747', marginTop: '20px' }}>
            * Required Fields
          </Typography>
        </Grid>
      </Stack>
    </>
  );
}
