import { m } from 'framer-motion';
// @mui
import { useEffect, useState } from 'react';
import { alpha } from '@mui/material/styles';
import {
  Button,
  Typography,
  TextField,
  Stack,
  Container,
  Grid,
  Stepper,
  StepLabel,
  Box,
  Step,
  Paper,
  StepContent,
  MenuItem,
  CardHeader,
  CardContent,
  Tooltip,
  Card,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
//
import { DatePicker } from '@mui/x-date-pickers';
import { varFade, MotionViewport } from '../../components/animate';
import { AppAreaInstalled } from '../@dashboard/general/app';
import PickerDate from '../_examples/mui/pickers/PickerDate';
import { ChartColumnSingle1 } from '../_examples/extra/chart';
import ConfettiExplosion from 'react-confetti-explosion';
import ChartMixed from '../_examples/extra/chart/ChartMixed1';
import Iconify from 'src/components/iconify/Iconify';
import {
  calculateBasePrice,
  calculateFourYearCost,
  generatePriceChartSeries,
  generateVolumeChartSeries,
  getProgramContacts,
  getProgramUsingDomain,
  submitPointsEarnContract,
  validateEmailContractRequest,
} from 'src/utils/chart_calculation_ver1';

import { useSnackbar } from '../../components/snackbar';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { TableHeadCustom } from 'src/components/table';
import wip_img from '../../assets/logo/WIP.png';
import ContractSummary from './ContractSummary';
import { CalculateTargetPrice } from './CalculateTargetPrice';
import { CalculateProjectedVolume } from './CalculateProjectedVolume';
import { OnboardingDetails } from './OnboardingDetails';
import { PartnershipDetails } from './PartnershipDetails';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------
const steps = [
  {
    label: 'Onboarding Details',
  },
  {
    label: 'Partnership Details',
  },
  {
    label: 'Calculate Projected Volume',
  },
  {
    label: 'Calculate Target Price',
  },
  {
    // commercial summary
    // label: 'Contract Summary',
    label: 'Commercial Summary',
  },
];
export default function OnboardingForm() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [programUsingDomain, setProgramUsingDomain] = useState({});
  const [validateEmail, setValidateEmail] = useState('');
  const [programContact, setProgramContacts] = useState([]);
  const [termsConditionDoc, setTermsConditionDoc] = useState('');
  // -------------------
  const currentDate = new Date();

  const futureDate = new Date(currentDate);
  futureDate.setDate(currentDate.getDate() + 15);

  console.log(futureDate);
  // ----------------

  const [companyDetails, setCompanyDetails] = useState({
    companyName: '',
    contactName: '',
    contactEmail: '',
    companyWebsite: '',
    // contactPhoneNo: '9715455333',
    // contactNumber
    contactPhoneNo: '',
    countryCode: '',
    companyAddress: '',
    // proposedDate: new Date()
    // proposedDate: futureDate,
    proposedDate: new Date(parseInt(new Date(new Date()).setDate(new Date().getDate() + 15)))
      .toISOString()
      .slice(0, 10),
    city: '',
    country: '',
    campaign1: '',
    campaign2: '',
    campaign3: '',
    additionalDetails: '',
  });

  // const [volume, setVolume] = useState({
  //   sales: 1,
  //   penetration: 2,
  //   proposition: 2,
  //   growth: 3,
  //   cashbackPer: 2,
  // });
  const [volume, setVolume] = useState({
    sales: 2000000,
    penetration: 0.2,
    proposition: 2,
    growth: 0.5,
    cashbackPer: 2,
  });

  const [price, setPrice] = useState({
    customerPerYear: 0,
    percentageNewToTheProgram: 1,
    percentageCollectAtOthers: 1,
    bonusIssuedPerYear: 1,
    minimumCommitment: 1,
    notice: 1,
    exclusivity: 3,
    payment: 1,
  });

  const [isChecked, setChecked] = useState(false);
  const [summary, setSummary] = useState({});

  console.log(companyDetails, 'companyDetails--');

  useEffect(() => {
    setVolume(generateVolumeChartSeries(volume, programUsingDomain));
    setPrice(generatePriceChartSeries(volume, price));
  });

  const handleChange = (event, base, type) => {
    if (base === 'companyDetails') {
      const newData = { ...companyDetails };
      newData[type] = event.target.value;
      setCompanyDetails(newData);
      console.log(newData);
    }
    if (base === 'volume') {
      const newData = { ...volume };
      newData[type] = event.target.value;
      // setVolume(newData);
      // console.log(newData);
      setVolume(generateVolumeChartSeries(newData));
    }

    if (base === 'price') {
      const newData = { ...price };
      newData[type] = event.target.value;
      // setPrice(newData);
      // console.log(newData);
      setPrice(generatePriceChartSeries(volume, newData));
    }
  };

  const isStepOptional = (step) => step === 1;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep == 0) {
      if (!companyDetails.companyName) {
        enqueueSnackbar('Company Name is required', {
          variant: 'error',
        });
        return;
      }
      if (!companyDetails.contactName) {
        enqueueSnackbar('Contact Name is required', {
          variant: 'error',
        });
        return;
      }
      if (!companyDetails.contactPhoneNo) {
        enqueueSnackbar('Contact Number is required', {
          variant: 'error',
        });
        return;
      }
      if (!companyDetails.contactEmail) {
        enqueueSnackbar('Contact Email-address is required', {
          variant: 'error',
        });
        return;
      }
      if (!companyDetails.companyWebsite) {
        enqueueSnackbar('Company Website is required', {
          variant: 'error',
        });
        return;
      }
    }

    // ProgramContacts

    if (activeStep == 4) {
      // push to backend service
      console.log(programContact, 'programContact==========+++++++++++++++++++++++');
      const response = submitPointsEarnContract({
        companyDetails: companyDetails,
        partnershipDetails: {},
        volume: volume,
        pricing: price,
        // programDetail: { ...programUsingDomain, programContacts: { ...programContact[0] } },
        programDetails: {
          ...programUsingDomain,
          contactEmail: programContact?.length > 0 ? programContact[0]?.contactEmail : '',
          contactName: programContact?.length > 0 ? programContact[0]?.contactName : '',
        },
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setChecked(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  // useEffect(()=>{
  //     const response = getProgramUsingDomain()
  //     const data = response
  //     console.log(data,"dattaaaaa")
  // },[])

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await getProgramUsingDomain();
    const data = response;
    setProgramUsingDomain(data);
  };

  // getProgramContacts

  // console.log(programUsingDomain,"programUsingDomain-----")

  // const generateChart = () => {
  //   // let sales = [1000000, 2000000, 5000000, 10000000, 20000000];
  //   // let penetration = [5, 10, 20, 50, 80];
  //   // let proposition = [1, 2, 5, 10, 20];
  //   // let growth = [5, 10, 20, 50, 80];

  //   // let recruitment = [100000, 200000, 500000, 1000000, 2000000, 5000000];
  //   // let percentageNewToProgram = [5, 10, 20, 30, 50, 80];
  //   // let percentageCollectAtOthers = [5, 10, 20, 30, 50, 80];
  //   // let bonusIssuedPerYear = [5, 10, 20, 30, 50, 80];
  //   // let minumumCommitment = [5, 10, 20, 30, 50, 80];

  //   // let noticePeriodDiscount = [20, 15, 10, 0, -20];
  //   // let exclusivityDiscount = [0, 30, -20, -10];
  //   // let paymentOptionDiscount = [-20, 0, 10, 20];

  //   let discountPerDollarBonus = 0.50
  //   let creditPerNewMember = 2
  //   let bonusPerPartnerActive = 1

  //   // volume calculation
  //   volume.annualLoyaltySales = getSalesList()[volume.sales].value * getVolumePenetrationList()[volume.penetration].value;
  //   volume.annualPointsPotential = Number(volume.annualLoyaltySales * getVolumePropositionList()[volume.proposition].value);

  //   volume.series = []

  //   volume.series[15] = Math.floor(volume.annualPointsPotential / 4);
  //   volume.series[3] = Math.floor(volume.series[15] * getGrowthList()[volume.growth].value);
  //   volume.series[0] = Math.floor(volume.series[3] / 4);
  //   volume.series[1] = Math.floor(volume.series[0] * 2);
  //   volume.series[2] = Math.floor(volume.series[0] * 3);
  //   volume.series[4] = Math.floor(volume.series[3] + ((volume.series[15] - volume.series[3]) / 12))
  //   volume.series[5] = Math.floor(volume.series[4] + ((volume.series[15] - volume.series[3]) / 12))
  //   volume.series[6] = Math.floor(volume.series[5] + ((volume.series[15] - volume.series[3]) / 12))
  //   volume.series[7] = Math.floor(volume.series[6] + ((volume.series[15] - volume.series[3]) / 12))
  //   volume.series[8] = Math.floor(volume.series[7] + ((volume.series[15] - volume.series[3]) / 12))
  //   volume.series[9] = Math.floor(volume.series[8] + ((volume.series[15] - volume.series[3]) / 12))
  //   volume.series[10] = Math.floor(volume.series[9] + ((volume.series[15] - volume.series[3]) / 12))
  //   volume.series[11] = Math.floor(volume.series[10] + ((volume.series[15] - volume.series[3]) / 12))
  //   volume.series[12] = Math.floor(volume.series[11] + ((volume.series[15] - volume.series[3]) / 12))
  //   volume.series[13] = Math.floor(volume.series[12] + ((volume.series[15] - volume.series[3]) / 12))
  //   volume.series[14] = Math.floor(volume.series[13] + ((volume.series[15] - volume.series[3]) / 12))

  //   console.log(volume);

  //   // price
  //   let contractThreshold = getContractThreshold();

  //   price.paymentDiscount = getPaymentList()[price.payment].value;
  //   price.commitementDiscount = getExclusivityList()[price.exclusivity].value + getNoticePeriodList()[price.notice].value;
  //   price.propositionDiscount = discountPerDollarBonus * (getBonusIssuedPerYear()[price.bonusIssuedPerYear].value);

  //   price.totalDiscount = Number(price.propositionDiscount) + Number(price.commitementDiscount) + Number(price.paymentDiscount);
  //   price.basePrice = Number(calculateBasePrice(volume.annualPointsPotential));
  //   price.targetPrice = Number(price.basePrice - (price.basePrice * (-price.totalDiscount))) || 0;

  //   price.annualMarketingContribution = price.customerPerYear * (price.percentageNewToTheProgram / 100) * 2 + (price.customerPerYear * (price.percentageNewToTheProgram / 100) * (price.percentageCollectAtOthers / 100) * 1)

  //   console.log(price);
  //   price.series = [];
  //   price.series[0] = Math.floor(volume.series[0] * price.basePrice);
  //   price.series[1] = Math.floor(volume.series[1] * price.basePrice);
  //   price.series[2] = Math.floor(volume.series[2] * price.basePrice);
  //   price.series[3] = Math.floor(volume.series[3] * price.basePrice);
  //   price.series[4] = Math.floor(volume.series[4] * price.basePrice);
  //   price.series[5] = Math.floor(volume.series[5] * price.basePrice);
  //   price.series[6] = Math.floor(volume.series[6] * price.basePrice);
  //   price.series[7] = Math.floor(volume.series[7] * price.basePrice);
  //   price.series[8] = Math.floor(volume.series[8] * price.basePrice);
  //   price.series[9] = Math.floor(volume.series[9] * price.basePrice);
  //   price.series[10] = Math.floor(volume.series[10] * price.basePrice);
  //   price.series[11] = Math.floor(volume.series[11] * price.basePrice);
  //   price.series[12] = Math.floor(volume.series[12] * price.basePrice);
  //   price.series[13] = Math.floor(volume.series[13] * price.basePrice);
  //   price.series[14] = Math.floor(volume.series[14] * price.basePrice);
  //   price.series[15] = Math.floor(volume.series[15] * price.basePrice);

  //   price.tierPricing = [(price.targetPrice + (price.targetPrice * (contractThreshold[0].premiumPrice / 100))), (price.targetPrice + (price.targetPrice * (contractThreshold[1].premiumPrice / 100))),
  //   (price.targetPrice + (price.targetPrice * (contractThreshold[2].premiumPrice / 100))),
  //   (price.targetPrice + (price.targetPrice * (contractThreshold[3].premiumPrice / 100))),
  //   (price.targetPrice + (price.targetPrice * (contractThreshold[4].premiumPrice / 100)))];

  //   price.tierVolume = [Math.floor((price.series[15] * (contractThreshold[0].volume / 100))), Math.floor((price.series[15] * (contractThreshold[1].volume / 100))),
  //   Math.floor((price.series[15] * (contractThreshold[2].volume / 100))),
  //   Math.floor((price.series[15] * (contractThreshold[3].volume / 100))),
  //   Math.floor((price.series[15] * (contractThreshold[4].volume / 100))), 0];
  // }

  const handleReset = () => {
    setActiveStep(0);
  };

  const validateEmailContract = async () => {
    // console.log(companyDetails.contactEmail,"companyDetails.contactEmail")
    const response = await validateEmailContractRequest({ email: companyDetails.contactEmail });

    //   if(response.status === 400){
    // console.log("----------------------")
    //   }
    setValidateEmail(response.status);

    // console.log(response)
  };

  console.log(companyDetails, 'comapny5555555');
  console.log(volume, 'volume----------');

  const location = useLocation();
  const currentUrl = location.pathname;
  console.log(currentUrl);
  return (
    <Container component={MotionViewport} sx={{ pb: 10, textAlign: 'center' }}>
      <m.div>
        <Typography variant="h2" sx={{ my: 3 }}>
          Points Earn Contract
        </Typography>
      </m.div>

      {activeStep === 5 && (
        <div style={{ alignItems: 'center', position: 'relative', left: '40%' }}>
          <ConfettiExplosion force={0.7} duration={5000} particleCount={300} width={1920} />
        </div>
      )}

      <m.div>
        <Typography
          sx={{
            mx: 'auto',
            maxWidth: 640,
            color: 'text.secondary',
          }}
        >
          Share the below details as per your needs and will create the custom tailored
          contract/agreement just for you
        </Typography>
      </m.div>
      <Stack
        style={{ padding: '40px' }}
        component={MotionViewport}
        spacing={3}
        sx={{ pb: 10, textAlign: 'left' }}
      >
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((item) => (
            <Step key={item.label}>
              <StepLabel>{item.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,

                // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >
              <OnboardingDetails
                companyDetails={companyDetails}
                handleChange={handleChange}
                setCompanyDetails={setCompanyDetails}
              />
            </Paper>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              {/* ---------if email already exist then message will show on next click------------ */}
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
              {/* <Button variant="contained" onClick={validateEmailContract}>Next.....
              </Button> */}
            </Box>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >
              <PartnershipDetails companyDetails={companyDetails} handleChange={handleChange} />
            </Paper>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            </Box>
          </>
        )}
        {activeStep === 2 && (
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >
              <CalculateProjectedVolume
                volume={volume}
                handleChange={handleChange}
                programUsingDomain={programUsingDomain}
              />
            </Paper>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              <Button variant="contained" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </>
        )}
        {activeStep === 3 && (
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >
              {/* 
                

              */}
              <CalculateTargetPrice volume={volume} price={price} handleChange={handleChange} />
            </Paper>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            </Box>
          </>
        )}
        {activeStep === 4 && (
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >
              <ContractSummary
                programUsingDomain={programUsingDomain}
                companyDetails={companyDetails}
                volume={volume}
                price={price}
                isChecked={isChecked}
                toogleCheck={() => setChecked(!isChecked)}
                setProgramContacts={setProgramContacts}
                programContact={programContact}
                termsConditionDoc={termsConditionDoc}
                setTermsConditionDoc={setTermsConditionDoc}
              />
            </Paper>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                variant="contained"
                disabled={!isChecked || termsConditionDoc === ''}
                onClick={handleNext}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
        {activeStep === 5 && (
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >
              <Typography sx={{ my: 1 }}>
                Congratulations!!! Your custom contract request is completed and sent to the team.
                Our team will get in touch with you shortly.
              </Typography>
              <Button
                sx={{ marginTop: '20px' }}
                variant="contained"
                // onClick={() => {
                //   navigate('/v1/partner-portal');
                // }}
                onClick={() => {
                  navigate('/');
                }}
              >
                Go to Home
              </Button>
            </Paper>
          </>
        )}
      </Stack>
    </Container>
  );
}
