import * as Yup from 'yup';
// @mui
import { Grid, Box, Typography, Button, TextField } from '@mui/material';
import { LandingPageImages } from 'src/assets/images';
import Image from 'src/components/image';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import { RHFTextField } from 'src/components/hook-form';
import Textfields from 'src/sections/_examples/mui/Textfields';
import Logo from 'src/components/logo';
import { NavItem } from 'src/layouts/main/nav/desktop/NavItem';
import CustomRHFTextField from 'src/components/hook-form/CustomRHFTextField';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { sendGetInTouchEmail } from 'src/utils/chart_calculation_ver1';

const NAV_ITEMS = [
  { title: 'Home', path: '/' },
  { title: 'About', path: '/' },
  { title: 'Earn Miles', path: '/' },
  { title: 'Burn Miles', path: '/' },
  { title: 'Become A Partner', path: '/' },
  { title: 'Support', path: '/' },
];

export default function GetInTouch() {
  const [getInTouch, setGetInTouch] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
  });
  const [emailSuccess, setEmailSuccess] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const GetInTouchSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    contactNo: Yup.string().required(''),
    message: Yup.string(),
  });

  const defaultValues = {
    name: '',
    email: '',
    contactNo: '',
    message: '',
  };

  const methods = useForm({
    resolver: yupResolver(GetInTouchSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {};

  const handleChange = (event, base, type) => {
    if (base === 'getInTouch') {
      const newData = { ...getInTouch };
      newData[type] = event.target.value;
      setGetInTouch(newData);
      //   console.log(newData);
    }
  };

  //   console.log(getInTouch, 'getInTouch-----');

  const handleSubmitEmailSent = async (data) => {
    // console.log('email sent');
    if (!getInTouch.email) {
      enqueueSnackbar('Email is required', {
        variant: 'error',
      });
      return;
    }

    // sendGetInTouchEmail
    const response = await sendGetInTouchEmail({
      name: getInTouch.name,
      email: getInTouch.email,
      contact: getInTouch.contact,
      message: getInTouch.message,
    });

    setEmailSuccess(response);
    setGetInTouch({
      name: '',
      email: '',
      contact: '',
      message: '',
    });
  };

  useEffect(() => {
    if (emailSuccess?.isSuccess === true) {
      enqueueSnackbar('Email sent.', {
        variant: 'success',
      });
    }
    // console.log(emailSuccess, 'emailSuccess---------');
  }, [emailSuccess]);

  return (
    <>
      {/* Get In Touch Navbar */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#D1D1D1',
          paddingX: 10,
          paddingY: 1,
        }}
      >
        <Logo />

        <Box sx={{ display: 'flex', gap: 5 }}>
          {NAV_ITEMS.map((item, i) => (
            <NavItem item={item} key={i} />
          ))}
        </Box>
      </Box>

      {/* Get In Touch Header */}
      <Grid container p={5} paddingX={10} spacing={2}>
        <Grid item xs={8}>
          <Box>
            <Typography variant="h2">
              Get <span style={{ color: '#3464B3' }}>in touch!</span>
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="title" sx={{ fontSize: '20px' }}>
                Have questions?
              </Typography>
              <Typography variant="title" sx={{ fontSize: '20px', width: '70%' }}>
                Let's chat! Reach out and get in touch for personalized assistance and solutions
                tailored just for you.
              </Typography>
            </Box>

            <Image
              sx={{ borderRadius: '20px', width: '80%', mt: 5 }}
              src={LandingPageImages.getInTouchImage1}
              alt="Get In Touch Image"
            />
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box>
            <FormProvider methods={methods} onSubmit={() => {}}>
              {/* <Box>
                <RHFTextField
                  name='name'
                  label='Name'
                  placeholder='Enter you name'
                  sx={{ mb: 3 }}
                />

                <RHFTextField
                  name='email'
                  label='Email'
                  placeholder='Enter you email'
                  sx={{ mb: 3 }}
                />
                <RHFTextField
                  name='contactNo'
                  label='Contact No.'
                  placeholder='Enter your contact no.'
                  sx={{ mb: 3 }}
                />

                <RHFTextField
                  name='message'
                  placeholder='Write a message...'
                  label='How can we help you?'
                  multiline
                  rows={3}
                  maxRows={5}
                  fullWidth
                  sx={{ mb: 3 }}
                />
              </Box> */}

              <Box>
                {/* <CustomRHFTextField
                  name="name"
                  label="Name"
                  placeholder="Enter you name"
                  sx={{ mb: 3 }}
                  
                />

                <CustomRHFTextField
                  name="email"
                  label="Email"
                  placeholder="Enter you email"
                  sx={{ mb: 3 }}
                />
                <CustomRHFTextField
                  name="contactNo"
                  label="Contact No."
                  placeholder="Enter your contact no."
                  sx={{ mb: 3 }}
                />

                <CustomRHFTextField
                  name="message"
                  placeholder="Write a message..."
                  label="How can we help you?"
                  multiline
                  rows={3}
                  maxRows={5}
                  fullWidth
                  sx={{ mb: 3 }}
                /> */}

                {/* email: '',
    contactNo: '',
    message: '', */}

                <Typography style={{ fontSize: '1.5rem', fontWeight: 700 }}>Name</Typography>
                <TextField
                  //   size="small"
                  fullWidth
                  name="name"
                  placeholder="Enter your name."
                  sx={{ mb: 3 }}
                  id="name"
                  onChange={(e) => handleChange(e, 'getInTouch', 'name')}
                  value={getInTouch.name}
                />

                <Typography style={{ fontSize: '1.5rem', fontWeight: 700 }}>Email</Typography>
                <TextField
                  //   size="small"
                  //   fullWidth
                  //   label="Name"
                  //   id="companyName"
                  fullWidth
                  name="email"
                  placeholder="Enter your email."
                  id="email"
                  sx={{ mb: 3 }}
                  onChange={(e) => handleChange(e, 'getInTouch', 'email')}
                  value={getInTouch.email}
                />

                <Typography style={{ fontSize: '1.5rem', fontWeight: 700, marginBottom: '10px' }}>
                  Contact No.
                </Typography>
                <TextField
                  fullWidth
                  name="contact"
                  placeholder="Enter your contact no."
                  sx={{ mb: 3 }}
                  id="contact"
                  type="number"
                  onChange={(e) => handleChange(e, 'getInTouch', 'contact')}
                  value={getInTouch.contact}
                />

                <Typography style={{ fontSize: '1.5rem', fontWeight: 700, marginBottom: '10px' }}>
                  How can we help you?
                </Typography>
                <TextField
                  name="message"
                  placeholder="Write a message..."
                  multiline
                  rows={3}
                  maxRows={5}
                  fullWidth
                  sx={{ mb: 3 }}
                  id="message"
                  onChange={(e) => handleChange(e, 'getInTouch', 'message')}
                  value={getInTouch.message}
                />
              </Box>

              <Button
                onClick={() => handleSubmitEmailSent()}
                sx={{ width: '100%' }}
                size="large"
                variant="contained"
              >
                Submit
              </Button>
            </FormProvider>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
