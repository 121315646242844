import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Card, Typography, Stack } from '@mui/material';
// utils
import { fNumber, fPercent } from '../../../../utils/formatNumber';
// components
import Iconify from '../../../../components/iconify';
import Chart from '../../../../components/chart';

// ----------------------------------------------------------------------

AppWidgetSummary1.propTypes = {
  sx: PropTypes.object,
  chart: PropTypes.object,
  title: PropTypes.string,
  color: PropTypes.string,
  total: PropTypes.number,
  percent: PropTypes.number,
};

export default function AppWidgetSummary1({ title, total, unit, color }) {

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="h4" style={{ color: color || '#637381' }}> {unit}{total}</Typography>
      </Box>
      </>
  );
}
