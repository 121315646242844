import { m } from 'framer-motion';
// @mui
import { useEffect, useState } from 'react';
import { alpha } from '@mui/material/styles';
import {
  Button, Typography, TextField, Stack, Container, Grid,
  Stepper,
  StepLabel,
  Box,
  Step,
  Paper,
  StepContent,
  MenuItem,
  CardHeader,
  CardContent,
  Tooltip,
  Card,
} from '@mui/material';
//
import { DatePicker } from '@mui/x-date-pickers';
import { varFade, MotionViewport } from '../../components/animate';
import { AppAreaInstalled } from '../@dashboard/general/app';
import PickerDate from '../_examples/mui/pickers/PickerDate';
import { ChartColumnSingle1 } from '../_examples/extra/chart';
import ConfettiExplosion from 'react-confetti-explosion';
import ChartMixed from '../_examples/extra/chart/ChartMixed1';
import Iconify from 'src/components/iconify/Iconify';
import { calculateBasePrice, getBonusIssuedPerYear, getMinimumCommitment } from 'src/utils/chart_calculation_ver1';
import { useSnackbar } from '../../components/snackbar';


// ----------------------------------------------------------------------
const steps = [
  {
    label: 'Onboarding Details',
  },
  {
    label: 'Partnership Details',
  },
  {
    label: 'Calculate Projected Volume',
  },
  {
    label: 'Calculate Target Price',
  },
  {
    label: 'Contract Summary',
  },
];
export default function OnboardingForm() {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [companyDetails, setCompanyDetails] = useState({
    proposedDate: new Date()
  });
  const [volume, setVolume] = useState({
    sales: 1,
    penetration: 2,
    proposition: 2,
    growth: 3,
  });
  const [price, setPrice] = useState({
    customerPerYear: 1,
    percentageNewToTheProgram: 1,
    percentageCollectAtOthers: 1,
    bonusIssuedPerYear: 1,
    minimumCommitment: 1,
    notice: 1,
    exclusivity: 1,
    payment: 1


  });
  const [summary, setSummary] = useState({});


  useEffect(() => {
    generateChart();
  })


  const handleChange = (event, base, type) => {
    if (base === "companyDetails") {
      const newData = { ...companyDetails };
      newData[type] = event.target.value;
      setCompanyDetails(newData);
      console.log(newData);
    }
    if (base === "volume") {
      const newData = { ...volume };
      newData[type] = event.target.value;
      setVolume(newData);
      console.log(newData);
    }

    if (base === "price") {
      const newData = { ...price };
      newData[type] = event.target.value;
      setPrice(newData);
      console.log(newData);
    }

    generateChart();
  };


  const isStepOptional = (step) => step === 1;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep == 0) {
      if (!companyDetails.companyName) {
        enqueueSnackbar('Company Name is required', {
          variant: 'error',
        })
        return;
      }
      if (!companyDetails.contactName) {
        enqueueSnackbar('Contact Name is required', {
          variant: 'error',
        })
        return;
      }
      if (!companyDetails.contactPhoneNo) {
        enqueueSnackbar('Contact Number is required', {
          variant: 'error',
        })
        return;
      }
      if (!companyDetails.contactEmail) {
        enqueueSnackbar('Contact Email-address is required', {
          variant: 'error',
        })
        return;
      }
      if (!companyDetails.companyWebsite) {
        enqueueSnackbar('Company Website is required', {
          variant: 'error',
        })
        return;
      }
    }

    if (activeStep == 4) {
      localStorage.setItem("contract", JSON.stringify({ companyDetails, volume, price, status: "pending" }));
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const generateChart = () => {
    let sales = [1000000, 2000000, 5000000, 10000000, 20000000];
    let penetration = [5, 10, 20, 50, 80];
    let proposition = [1, 2, 5, 10, 20];
    let growth = [5, 10, 20, 50, 80];


    let recruitment = [100000, 200000, 500000, 1000000, 2000000, 5000000];
    let percentageNewToProgram = [5, 10, 20, 30, 50, 80];
    let percentageCollectAtOthers = [5, 10, 20, 30, 50, 80];
    let bonusIssuedPerYear = [5, 10, 20, 30, 50, 80];
    let minumumCommitment = [5, 10, 20, 30, 50, 80];

    let noticePeriodDiscount = [20, 15, 10, 0, -20];
    let exclusivityDiscount = [0, 30, -20, -10];
    let paymentOptionDiscount = [-20, 0, 10, 20];

    let discountPerDollarBonus = 1
    let creditPerNewMember = 2
    let bonusPerPartnerActive = 1

    // volume calculation
    volume.annualLoyaltySales = sales[volume.sales] * (penetration[volume.penetration] / 100);
    volume.annualPointsPotential = Number(volume.annualLoyaltySales * proposition[volume.proposition])

    volume.series = []

    volume.series[15] = Math.floor(volume.annualPointsPotential / 4);
    volume.series[3] = Math.floor(volume.series[15] * growth[volume.growth] / 100);
    volume.series[0] = Math.floor(volume.series[3] / 4);
    volume.series[1] = Math.floor(volume.series[0] * 2);
    volume.series[2] = Math.floor(volume.series[0] * 3);
    volume.series[4] = Math.floor(volume.series[3] + ((volume.series[15] - volume.series[3]) / 12))
    volume.series[5] = Math.floor(volume.series[4] + ((volume.series[15] - volume.series[3]) / 12))
    volume.series[6] = Math.floor(volume.series[5] + ((volume.series[15] - volume.series[3]) / 12))
    volume.series[7] = Math.floor(volume.series[6] + ((volume.series[15] - volume.series[3]) / 12))
    volume.series[8] = Math.floor(volume.series[7] + ((volume.series[15] - volume.series[3]) / 12))
    volume.series[9] = Math.floor(volume.series[8] + ((volume.series[15] - volume.series[3]) / 12))
    volume.series[10] = Math.floor(volume.series[9] + ((volume.series[15] - volume.series[3]) / 12))
    volume.series[11] = Math.floor(volume.series[10] + ((volume.series[15] - volume.series[3]) / 12))
    volume.series[12] = Math.floor(volume.series[11] + ((volume.series[15] - volume.series[3]) / 12))
    volume.series[13] = Math.floor(volume.series[12] + ((volume.series[15] - volume.series[3]) / 12))
    volume.series[14] = Math.floor(volume.series[13] + ((volume.series[15] - volume.series[3]) / 12))

    console.log(volume);

    // price
    price.paymentDiscount = paymentOptionDiscount[price.payment];
    price.commitementDiscount = exclusivityDiscount[price.exclusivity] + noticePeriodDiscount[price.notice];
    price.propositionDiscount = (discountPerDollarBonus / 100) * (price.bonusIssuedPerYear / 100) * 10;

    price.totalDiscount = Number(price.propositionDiscount) + Number(price.commitementDiscount) + Number(price.paymentDiscount);
    price.basePrice = calculateBasePrice(volume.annualPointsPotential);
    price.targetPrice = price.basePrice - (price.basePrice * (price.totalDiscount / 100));

    price.annualMarketingContribution = price.customerPerYear * (price.percentageNewToTheProgram / 100) * 2 + (price.customerPerYear * (price.percentageNewToTheProgram / 100) * (price.percentageCollectAtOthers / 100) * 1)

    console.log(price);
    price.series = [];
    price.series[0] = Math.floor(volume.series[0] * price.targetPrice);
    price.series[1] = Math.floor(volume.series[1] * price.targetPrice);
    price.series[2] = Math.floor(volume.series[2] * price.targetPrice);
    price.series[3] = Math.floor(volume.series[3] * price.targetPrice);
    price.series[4] = Math.floor(volume.series[4] * price.targetPrice);
    price.series[5] = Math.floor(volume.series[5] * price.targetPrice);
    price.series[6] = Math.floor(volume.series[6] * price.targetPrice);
    price.series[7] = Math.floor(volume.series[7] * price.targetPrice);
    price.series[8] = Math.floor(volume.series[8] * price.targetPrice);
    price.series[9] = Math.floor(volume.series[9] * price.targetPrice);
    price.series[10] = Math.floor(volume.series[10] * price.targetPrice);
    price.series[11] = Math.floor(volume.series[11] * price.targetPrice);
    price.series[12] = Math.floor(volume.series[12] * price.targetPrice);
    price.series[13] = Math.floor(volume.series[13] * price.targetPrice);
    price.series[14] = Math.floor(volume.series[14] * price.targetPrice);
    price.series[15] = Math.floor(volume.series[15] * price.targetPrice);

    price.tierPricing = [(price.targetPrice + (price.targetPrice * -0.1)), (price.targetPrice), (price.targetPrice + (price.targetPrice * 0.1)), (price.targetPrice + (price.targetPrice * 0.15)), (price.targetPrice + (price.targetPrice * 0.2))];
    price.tierVolume = [Math.floor(price.series[15]), Math.floor(price.series[15] * 0.75), Math.floor(price.series[15] * 0.50), Math.floor(price.series[15] * 0.25), 0];
  }

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container component={MotionViewport} sx={{ pb: 10, textAlign: 'center' }}>
      <m.div>
        <Typography variant="h2" sx={{ my: 3 }}>
          Custom contract made just for you
        </Typography>
      </m.div>

      {activeStep === 5 &&
        <div style={{ alignItems: 'center', position: 'relative', left: '40%' }}>
          <ConfettiExplosion
            force={0.7}
            duration={5000}
            particleCount={300}
            width={1920}
          />
        </div>
      }

      <m.div>
        <Typography
          sx={{
            mx: 'auto',
            maxWidth: 640,
            color: 'text.secondary',
          }}
        >
          Share the below details as per your needs and will create the custom tailored contract/agreement just for you
        </Typography>

      </m.div >
      <Stack style={{ padding: "40px" }} component={MotionViewport} spacing={3} sx={{ pb: 10, textAlign: 'left' }}>

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((item) => (
            <Step key={item.label}>
              <StepLabel>{item.label}</StepLabel>
            </Step>
          ))}

        </Stepper>
        {activeStep === 0 &&
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >
              <Stack style={{ padding: "40px" }} component={MotionViewport} spacing={3}>
                <Typography variant='h4' style={{ textAlign: 'left' }} >Company Details</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <m.div>
                      <TextField size='small' fullWidth label="Company Name*" value={companyDetails.companyName} id="companyName" onChange={(e) => handleChange(e, "companyDetails", "companyName")} />
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <m.div>
                      <TextField size='small' fullWidth label="Contact Name*" value={companyDetails.contactName} id="contactName" onChange={(e) => handleChange(e, "companyDetails", "contactName")} />
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <m.div>
                      <TextField size='small' fullWidth label="Contact Email address*" value={companyDetails.contactEmail} id="contactEmail" onChange={(e) => handleChange(e, "companyDetails", "contactEmail")} />
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <m.div>
                      <TextField size='small' fullWidth label="Contact PhoneNo*" type="number" value={companyDetails.contactPhoneNo} id="contactPhoneNo" onChange={(e) => handleChange(e, "companyDetails", "contactPhoneNo")} />
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <m.div>
                      <TextField size='small' fullWidth label="Company Address" value={companyDetails.companyAddress} id="companyAddress" onChange={(e) => handleChange(e, "companyDetails", "companyAddress")} />
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <m.div>
                      <TextField size='small' fullWidth label="Company Website*" value={companyDetails.companyWebsite} id="companyWebsite" onChange={(e) => handleChange(e, "companyDetails", "companyWebsite")} />
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField size='small' variant="outlined" select fullWidth label="Market" value={companyDetails.market} id="market" onChange={(e) => handleChange(e, "companyDetails", "market")} >
                      <MenuItem key="0" value="0">UK</MenuItem>
                      <MenuItem key="1" value="1">USA</MenuItem>
                      <MenuItem key="2" value="2">UAE</MenuItem>
                      <MenuItem key="3" value="3">India</MenuItem>
                    </TextField>
                    <m.div style={{ background: '#b5b5b5', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Country or region in which the partnership will be made available to collectors.</Typography>
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField size='small' variant="outlined" select fullWidth label="Category" value={companyDetails.category} id="category" onChange={(e) => handleChange(e, "companyDetails", "category")} >
                      <MenuItem key="0" value="0">Airlines</MenuItem>
                      <MenuItem key="1" value="1">Banks</MenuItem>
                      <MenuItem key="2" value="2">Hotels</MenuItem>
                      <MenuItem key="3" value="3">Financial Service</MenuItem>
                      <MenuItem key="4" value="4">Food & Beverages</MenuItem>
                      <MenuItem key="5" value="5">Others</MenuItem>
                    </TextField>
                    <m.div style={{ background: '#b5b5b5', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Category which best describes the partner’s main business</Typography>
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <m.div>
                      <TextField size='small' fullWidth label="Sub category" value={companyDetails.subCategory} id="subCategory" onChange={(e) => handleChange(e, "companyDetails", "subCategory")} />
                    </m.div>
                    <m.div style={{ background: '#b5b5b5', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Sub-category which best describes the partner’s main business</Typography>
                    </m.div>
                  </Grid>
                  <Typography variant='subtitle2' style={{ color: '#f44747' }}>* Required Fields</Typography>
                </Grid>
              </Stack>
            </Paper>
            <Box sx={{ display: 'flex' }}>
              <Button variant="contained" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" onClick={handleNext}>Next
              </Button>
            </Box>
          </>
        }
        {activeStep === 1 &&
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >
              <Stack style={{ padding: "40px" }} component={MotionViewport} spacing={3}>
                <Typography variant='h4' style={{ textAlign: 'left' }} >Partnership Details</Typography>
                <Typography variant='h6' style={{ textAlign: 'left' }} >Tell us more about your plan for the partnership</Typography>
                <TextField size='small' fullWidth label="Proposed Date" type="date" value={companyDetails.proposedDate} id="proposedDate" onChange={(e) => handleChange(e, "companyDetails", "proposedDate")} />

                <Typography variant='h6' style={{ textAlign: 'left' }}>Could you tell us briefly about why you would like to enter this partnership?</Typography>
                <m.div>
                  <TextField size='small' fullWidth label="Enter details here." multiline rows={4} value={companyDetails.additionalDetails} id="additionalDetails" onChange={(e) => handleChange(e, "companyDetails", "additionalDetails")} />
                </m.div>

                <Typography variant='h6' style={{ textAlign: 'left' }} >How will the partnership be promoted?</Typography>
                <p>for example social media, in store, etc.</p>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <m.div>
                      <TextField size='small' fullWidth label="Campaign 1" value={companyDetails.campaign1} id="campaign1" onChange={(e) => handleChange(e, "companyDetails", "campaign1")} />
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <m.div>
                      <TextField size='small' fullWidth label="Campaign 2" value={companyDetails.campaign2} id="campaign2" onChange={(e) => handleChange(e, "companyDetails", "campaign2")} />
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <m.div>
                      <TextField size='small' fullWidth label="Campaign 3" value={companyDetails.campaign3} id="campaign3" onChange={(e) => handleChange(e, "companyDetails", "campaign3")} />
                    </m.div>
                  </Grid>
                </Grid>
              </Stack>
            </Paper>
            <Box sx={{ display: 'flex' }}>
              <Button variant="contained" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" onClick={handleNext}>Next
              </Button>
            </Box>
          </>
        }
        {activeStep === 2 &&
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >

              <Stack style={{ padding: "40px" }} component={MotionViewport} spacing={3}>
                <Typography variant='h4' style={{ textAlign: 'left' }} >Calculate Projected Volume</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}>
                    <Typography variant='h6' sx={{ mb: '10px' }}>Sales</Typography>
                    <TextField size='small' variant="outlined" select fullWidth label="Sales ($)" value={volume.sales} id="volume.sales" onChange={(e) => handleChange(e, "volume", "sales")} >
                      <MenuItem key="0" value="0"> 1 Million </MenuItem>
                      <MenuItem key="1" value="1"> 2 Million </MenuItem>
                      <MenuItem key="2" value="2"> 5 Million </MenuItem>
                      <MenuItem key="3" value="3"> 10 Million </MenuItem>
                      <MenuItem key="4" value="4"> 20 Million </MenuItem>
                    </TextField>
                    <m.div style={{ background: '#868585', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Total annual sales for the proposition eligible for loyalty rewards?</Typography>
                    </m.div>

                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography variant='h6' sx={{ mb: '10px' }} >Penetration </Typography>
                    <TextField size='small' variant="outlined" select fullWidth label="Penetration (%)" value={volume.penetration} id="volume.penetration" onChange={(e) => handleChange(e, "volume", "penetration")} >
                      <MenuItem key="0" value="0"> 5% </MenuItem>
                      <MenuItem key="1" value="1"> 10% </MenuItem>
                      <MenuItem key="2" value="2"> 20% </MenuItem>
                      <MenuItem key="3" value="3"> 50% </MenuItem>
                      <MenuItem key="4" value="4"> 80% </MenuItem>
                    </TextField>
                    <m.div style={{ background: '#868585', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>What penetration of sales do you expect loyalty to reach in a mature year?</Typography>
                    </m.div>


                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography variant='h6' sx={{ mb: '10px' }}>Proposition</Typography>
                    <TextField size='small' variant="outlined" select fullWidth label="Proposition" value={volume.proposition} id="volume.proposition" onChange={(e) => handleChange(e, "volume", "proposition")} >
                      <MenuItem key="0" value="0"> 1 point per $ </MenuItem>
                      <MenuItem key="1" value="1"> 2 point per $ </MenuItem>
                      <MenuItem key="2" value="2"> 5 point per $ </MenuItem>
                      <MenuItem key="3" value="3"> 10 point per $ </MenuItem>
                      <MenuItem key="4" value="4"> 20 point per $ </MenuItem>
                    </TextField>

                    <m.div style={{ background: '#868585', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>Roughly what reward rate per $ spent will the proposition give members?</Typography>
                    </m.div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography variant='h6' sx={{ mb: '10px' }}>Growth</Typography>
                    <TextField size='small' variant="outlined" select fullWidth label="Growth (% in first year)" value={volume.growth} id="volume.growth" onChange={(e) => handleChange(e, "volume", "growth")} >
                      <MenuItem key="0" value="0"> 5% </MenuItem>
                      <MenuItem key="1" value="1"> 10% </MenuItem>
                      <MenuItem key="2" value="2"> 20% </MenuItem>
                      <MenuItem key="3" value="3"> 50% </MenuItem>
                      <MenuItem key="4" value="4"> 80% </MenuItem>
                    </TextField>
                    <m.div style={{ background: '#868585', color: 'white', padding: '10px', margin: '10px' }}>
                      <Typography variant='caption' style={{ marginBottom: '10px' }}>What % of mature year volume do you expect to reach in the first 12 months?</Typography>
                    </m.div>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <Card dir="ltr">
                      {/* <CardHeader style={{ color: '#11c085' }} title={`${volume.annualPointsPotential.toLocaleString('en-US')} points/year`} /> */}
                      <Typography variant='h4' style={{ color: '#11c085' }}>${volume.annualPointsPotential.toLocaleString('en-US')} points/year</Typography>
                      <CardContent>
                        <ChartColumnSingle1
                          xAxisLabel="Years"
                          yAxisLabel="Points Volume (in USD)"
                          unit="USD"
                          categories={[
                            'Year1 Q1', 'Year1 Q2', 'Year1 Q3', 'Year1 Q4',
                            'Year2 Q1', 'Year2 Q2', 'Year2 Q3', 'Year2 Q4',
                            'Year3 Q1', 'Year3 Q2', 'Year3 Q3', 'Year3 Q4',
                            'Year4 Q1', 'Year4 Q2', 'Year4 Q3', 'Year4 Q4',
                          ]}
                          series={[
                            {
                              name: 'Projected Volume',
                              type: 'column',
                              data: volume.series,
                            }
                          ]} />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Stack>
            </Paper>
            <Box sx={{ display: 'flex' }}>
              <Button variant="contained" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              <Button variant="contained" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </>
        }
        {activeStep === 3 &&
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >
              {/* 
                

              */}
              <Stack style={{ padding: "40px" }} component={MotionViewport} spacing={3}>
                <Typography variant='h4' style={{ textAlign: 'left' }} >Calculate Targeted Price</Typography>
                <Grid container spacing={3}>

                  <Grid item xs={12} md={3}>
                    <Typography sx={{ my: 1 }} variant='h6'>Penetration</Typography>
                    <TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Bonus issued per year (%)" value={price.bonusIssuedPerYear} id="price.bonusIssuedPerYear" onChange={(e) => handleChange(e, "price", "bonusIssuedPerYear")} >
                      <MenuItem key="0" value="0"> 5% </MenuItem>
                      <MenuItem key="1" value="1"> 10% </MenuItem>
                      <MenuItem key="2" value="2"> 20% </MenuItem>
                      <MenuItem key="3" value="3"> 30% </MenuItem>
                      <MenuItem key="4" value="4"> 50% </MenuItem>
                      <MenuItem key="5" value="5"> 80% </MenuItem>
                    </TextField>
                  </Grid><Grid item xs={12} md={3}>
                    <Typography sx={{ my: 1 }} variant='h6'>Proposition</Typography>
                    <TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Minimum Commitment (%)" value={price.minimumCommitment} id="price.minimumCommitment" onChange={(e) => handleChange(e, "price", "minimumCommitment")} >
                      <MenuItem key="0" value="0"> 5% </MenuItem>
                      <MenuItem key="1" value="1"> 10% </MenuItem>
                      <MenuItem key="2" value="2"> 20% </MenuItem>
                      <MenuItem key="3" value="3"> 30% </MenuItem>
                      <MenuItem key="4" value="4"> 50% </MenuItem>
                      <MenuItem key="5" value="5"> 80% </MenuItem>
                    </TextField>
                    <TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Notice" value={price.notice} id="price.notice" onChange={(e) => handleChange(e, "price", "notice")} >
                      <MenuItem key="0" value="0"> 1 Month </MenuItem>
                      <MenuItem key="1" value="1"> 3 Months </MenuItem>
                      <MenuItem key="2" value="2"> 1 Year </MenuItem>
                      <MenuItem key="3" value="3"> 3 Years </MenuItem>
                      <MenuItem key="3" value="3"> 5 Years </MenuItem>
                    </TextField>
                    <TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Exclusivity" value={price.exclusivity} id="price.exclusivity" onChange={(e) => handleChange(e, "price", "exclusivity")} >
                      <MenuItem key="0" value="0"> None </MenuItem>
                      <MenuItem key="1" value="1"> Us exclusive to you </MenuItem>
                      <MenuItem key="2" value="2"> You exclusive to us </MenuItem>
                      <MenuItem key="3" value="3"> Mutual exclusivity </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography sx={{ my: 1 }} variant='h6'>Commitment</Typography>
                    <TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Payment" value={price.payment} id="price.payment" onChange={(e) => handleChange(e, "price", "payment")} >
                      <MenuItem key="0" value="0"> Upfront </MenuItem>
                      <MenuItem key="1" value="1"> 30 days </MenuItem>
                      <MenuItem key="2" value="2"> 60 days </MenuItem>
                      <MenuItem key="3" value="3"> 90 days </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography sx={{ my: 1 }} variant='h6'>Recruitment</Typography>
                    <TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Customer per year" value={price.customerPerYear} id="price.customerPerYear" onChange={(e) => handleChange(e, "price", "customerPerYear")} >
                      <MenuItem key="0" value="0"> 100K </MenuItem>
                      <MenuItem key="1" value="1"> 200K </MenuItem>
                      <MenuItem key="2" value="2"> 500K </MenuItem>
                      <MenuItem key="3" value="3"> 1M </MenuItem>
                      <MenuItem key="4" value="4"> 2M </MenuItem>
                      <MenuItem key="5" value="5"> 5M </MenuItem>
                    </TextField>
                    <TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="New to the program (%)" value={price.percentageNewToTheProgram} id="price.percentageNewToTheProgram" onChange={(e) => handleChange(e, "price", "percentageNewToTheProgram")} >
                      <MenuItem key="0" value="0"> 5% </MenuItem>
                      <MenuItem key="1" value="1"> 10% </MenuItem>
                      <MenuItem key="2" value="2"> 20% </MenuItem>
                      <MenuItem key="3" value="3"> 30% </MenuItem>
                      <MenuItem key="4" value="4"> 50% </MenuItem>
                      <MenuItem key="5" value="5"> 80% </MenuItem>
                    </TextField>
                    <TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Collect at other partners (%)" value={price.percentageCollectAtOthers} id="price.percentageCollectAtOthers" onChange={(e) => handleChange(e, "price", "percentageCollectAtOthers")} >
                      <MenuItem key="0" value="0"> 5% </MenuItem>
                      <MenuItem key="1" value="1"> 10% </MenuItem>
                      <MenuItem key="2" value="2"> 20% </MenuItem>
                      <MenuItem key="3" value="3"> 30% </MenuItem>
                      <MenuItem key="4" value="4"> 50% </MenuItem>
                      <MenuItem key="5" value="5"> 80% </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Card dir="ltr">
                      <Typography variant='h4' style={{ color: '#11c085', marginBottom: '20px' }}>Target Price ${price.targetPrice.toLocaleString('en-US')}</Typography>

                      <CardContent>
                        <ChartColumnSingle1
                          xAxisLabel="Years"
                          yAxisLabel="Spend (in USD)"
                          unit="Volume Spend (in USD)"
                          categories={[
                            'Year1 Q1', 'Year1 Q2', 'Year1 Q3', 'Year1 Q4',
                            'Year2 Q1', 'Year2 Q2', 'Year2 Q3', 'Year2 Q4',
                            'Year3 Q1', 'Year3 Q2', 'Year3 Q3', 'Year3 Q4',
                            'Year4 Q1', 'Year4 Q2', 'Year4 Q3', 'Year4 Q4',
                          ]}
                          series={[
                            {
                              name: 'Total Spend',
                              type: 'column',
                              data: price.series,
                            },
                            // {
                            //   name: `Stretch Price: ${Number(price.tierPricing[0]).toFixed(4)} (Over & Above $${price.tierVolume[0]})`,
                            //   type: 'area',
                            //   data: [price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0],
                            //   price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0],
                            //   price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0],
                            //   price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0]]
                            // },
                            {
                              name: `Target Price: ${Number(price.tierPricing[1]).toFixed(4)} ($${price.tierVolume[1]} - $${price.tierVolume[0]})`,
                              type: 'area',
                              data: [price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0],
                              price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0],
                              price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0],
                              price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0]]
                            },
                            {
                              name: `Level Three Price: ${Number(price.tierPricing[2]).toFixed(4)} ($${price.tierVolume[2]} - $${price.tierVolume[1]})`,
                              type: 'area',
                              data: [price.tierVolume[1], price.tierVolume[1], price.tierVolume[1], price.tierVolume[1],
                              price.tierVolume[1], price.tierVolume[1], price.tierVolume[1], price.tierVolume[1],
                              price.tierVolume[1], price.tierVolume[1], price.tierVolume[1], price.tierVolume[1],
                              price.tierVolume[1], price.tierVolume[1], price.tierVolume[1], price.tierVolume[1]]
                            },
                            {
                              name: `Level Two Price: ${Number(price.tierPricing[3]).toFixed(4)}  ($${price.tierVolume[3]} - $${price.tierVolume[2]})`,
                              type: 'area',
                              data: [price.tierVolume[2], price.tierVolume[2], price.tierVolume[2], price.tierVolume[2],
                              price.tierVolume[2], price.tierVolume[2], price.tierVolume[2], price.tierVolume[2],
                              price.tierVolume[2], price.tierVolume[2], price.tierVolume[2], price.tierVolume[2],
                              price.tierVolume[2], price.tierVolume[2], price.tierVolume[2], price.tierVolume[2]]
                            },
                            {
                              name: `Level One Price: $${Number(price.tierPricing[4]).toFixed(4)} ($${price.tierVolume[4]} - $${price.tierVolume[3]})`,
                              type: 'area',
                              data: [price.tierVolume[3], price.tierVolume[3], price.tierVolume[3], price.tierVolume[3],
                              price.tierVolume[3], price.tierVolume[3], price.tierVolume[3], price.tierVolume[3],
                              price.tierVolume[3], price.tierVolume[3], price.tierVolume[3], price.tierVolume[3],
                              price.tierVolume[3], price.tierVolume[3], price.tierVolume[3], price.tierVolume[3]]
                            },
                          ]} />
                      </CardContent>
                    </Card>


                  </Grid>
                </Grid>
              </Stack>
            </Paper>
            <Box sx={{ display: 'flex' }}>
              <Button variant="contained" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" onClick={handleNext}>Next
              </Button>
            </Box>
          </>
        }
        {activeStep === 4 &&
          <>

            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >
              <Typography variant='h6'>Points Earn Agreement</Typography>
              <br />
              <Typography>This agreement is dated  on <b>{new Date().toLocaleString()} GST</b> and is between <b>{companyDetails.companyName}</b>  whose office is registered at  {companyDetails.companyName}. {companyDetails.companyAddress} and
                <b> Emirates Group Technology Center (EGTC) </b>, Emirates Group Technology Centre (Old Head Quarters), Clock Tower - Al Maktoum Rd - Dubai.</Typography><br />
              <Typography variant='h6'>Terms Sheet:</Typography><br />
              <table>
                <tr><td width="20%"><b>Partner Representative</b> </td>
                  <td width="20%">{companyDetails.contactName} </td>
                  <td><b>Point of Contact</b> </td>
                  <td>{companyDetails.contactEmail} </td></tr>
                <tr>
                  <td><b>Program Representative</b> </td>
                  <td>Jane Jonas </td>
                  <td><b>Point of Contact</b> </td>
                  <td>jane.jonas@emiratesairlines.com </td>
                </tr>
                <tr>
                  <td><b>Commencment Date</b> </td>
                  <td>31th March 2023 </td>
                  <td colSpan="2">Means the latest possible date the service will be available to members.	 </td>
                </tr>
                <tr>
                  <td><b>Initial Term</b> </td>
                  <td>1 Year/s </td>
                  <td colSpan="2">From the Commencement Date with the right to review after 12 months in the event that total payments from Partner do not exceed $36,000 in any 12 month period.
                  </td>
                </tr>
                <tr>
                  <td><b>Projected Volume</b> </td>
                  <td>{volume.annualLoyaltySales.toLocaleString('en-US')} </td>
                  <td colSpan="2">* Relevant words and text to describe this particular contract commitment.
                  </td>
                </tr>
                <tr>
                  <td><b>Bonus Points %</b> </td>
                  <td>{getBonusIssuedPerYear()[price.bonusIssuedPerYear].name} </td>
                  <td colSpan="2">* Relevant words and text to describe this particular contract commitment.
                  </td>
                </tr>
                <tr>
                  <td><b>Minimum Commitment</b> </td>
                  <td>{(volume.annualLoyaltySales * getMinimumCommitment()[price.minimumCommitment].value).toLocaleString('en-US')} </td>
                  <td colSpan="2">* Relevant words and text to describe this particular contract commitment.
                  </td>
                </tr>
              </table>
            </Paper>
            <Box sx={{ display: 'flex' }}>
              <Button variant="contained" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" onClick={handleNext}>Finish
              </Button>
            </Box>
          </>
        }
        {activeStep === 5 &&
          <>
            <Paper
              sx={{
                p: 3,
                my: 3,
                minHeight: 120,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
              }}
            >

              <Typography sx={{ my: 1 }}>Congratulations!!! Your custom contract request is completed and sent to Emirates Airlines team. Our team will get in touch with you shortly.</Typography>
            </Paper>
          </>
        }
      </Stack >
    </Container >

  );
}
