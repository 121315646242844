import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import SimpleWithoutHeader from '../layouts/simpleWithoutHeader';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  DemoMarkdownPage,
  FeaturesPage,
  PartnerOnboardingPage,
  SmartContractViewPage,
  SmartContractsListPage,
  ReportingViewPage,
  ReportingViewPage2,
  ReportingViewPage3,
  ReportingViewPage4,
  ReportingViewPage5,
  ReportingViewPage6,
  OperatorConfigurationPage,
  ReportingViewPage7,
  ReportingViewPage9,
  ReportingViewPage8,
  ReportingViewPage1,
  OpeartorTilesPage,
  VideoPage,
  VideoPageV2,
  PartnerTilesPage,
  PointsBurnContractPage,
  PaymentInvoicingPage,
  ReportingPage,
  PartnershipBenefitsPage,
  CustomerServicesPage,
  AdvertisementPage,
  PromotionsPage,
  SurveyPage,
  PreviewVideoPage,
  InteractivePage,
  OperatorReportingPage,
  OperatorBurnContract,
  PointsBurnSmartContractsListPage,
  PointsBurnSmartcontractViewPage,
  BurnOperatorConfigurationPage,
  MiscOperatorConfigurationPage,
  ReportingPartnershipPerformance,
  PartnerOnboardingPageV2,
} from './elements';
import IntroductionPage from 'src/pages/IntroductionPage';
import LandingPage from 'src/pages/LandingPage';
import { PartnerOnboardingPageV3 } from 'src/pages/PartnerOnboardingPageV3';
import GetInTouch from 'src/pages/GetInTouch';
import { PartnerOnboardingDetailPage } from 'src/pages/PartnerOnboardingDetailPage';
import Redemption from 'src/pages/Redemption';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        { path: 'features', element: <FeaturesPage /> },
        { path: 'ecommerce', element: <GeneralEcommercePage /> },
        { path: 'analytics', element: <GeneralAnalyticsPage /> },
        { path: 'banking', element: <GeneralBankingPage /> },
        { path: 'booking', element: <GeneralBookingPage /> },
        { path: 'file', element: <GeneralFilePage /> },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShopPage /> },
            { path: 'product/:name', element: <EcommerceProductDetailsPage /> },
            { path: 'list', element: <EcommerceProductListPage /> },
            { path: 'product/new', element: <EcommerceProductCreatePage /> },
            { path: 'product/:name/edit', element: <EcommerceProductEditPage /> },
            { path: 'checkout', element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: 'partner',
          children: [
            { element: <Navigate to="/dashboard/partner/onboarding" replace />, index: true },
            { path: 'onboarding', element: <UserProfilePage /> },
            { path: 'volume', element: <UserCardsPage /> },
            { path: 'price', element: <UserListPage /> },
            { path: 'contract-summary', element: <UserCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'smartcontract',
          children: [
            {
              element: <Navigate to="/dashboard/smartcontract/points-earnlist" replace />,
              index: true,
            },
            { path: 'points-earn/list', element: <SmartContractsListPage /> },
            { path: 'view/:identifier', element: <SmartContractViewPage /> },
            { path: 'points-burn/list', element: <PointsBurnSmartContractsListPage /> },
            { path: 'points-burn/view/:identifier', element: <PointsBurnSmartcontractViewPage /> },
          ],
        },
        {
          path: 'reporting',
          children: [
            {
              element: <Navigate to="/dashboard/reporting/partnership-performance" replace />,
              index: true,
            },
            { path: 'partnership-performance', element: <ReportingPartnershipPerformance /> },
            { path: 'performance-priorities', element: <ReportingViewPage /> },
            { path: 'recruitment-performance', element: <ReportingViewPage /> },
            { path: 'pricing', element: <ReportingViewPage /> },
            { path: 'customer-priorities', element: <ReportingViewPage /> },
            { path: 'partner-risk', element: <ReportingViewPage /> },
            // { path: 'view', element: <ReportingViewPage /> },
            // { path: 'view1', element: <ReportingViewPage1 /> },
            // { path: 'view2', element: <ReportingViewPage2 /> },
            // { path: 'view3', element: <ReportingViewPage3 /> },
            // { path: 'view4', element: <ReportingViewPage4 /> },
            // { path: 'view5', element: <ReportingViewPage5 /> },
            // { path: 'view6', element: <ReportingViewPage6 /> },
            // { path: 'view7', element: <ReportingViewPage7 /> },
            // { path: 'view8', element: <ReportingViewPage8 /> },
            // { path: 'view9', element: <ReportingViewPage9 /> },
          ],
        },
        {
          path: 'configuration',
          children: [
            {
              element: <Navigate to="/dashboard/configuration/points-earn-contract" replace />,
              index: true,
            },
            { path: 'points-earn-contract', element: <OperatorConfigurationPage /> },
            { path: 'points-burn-contract', element: <BurnOperatorConfigurationPage /> },
            { path: 'miscellaneous', element: <MiscOperatorConfigurationPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPostsPage /> },
            { path: 'post/:title', element: <BlogPostPage /> },
            { path: 'new', element: <BlogNewPostPage /> },
          ],
        },
        { path: 'files-manager', element: <FileManagerPage /> },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <MailPage /> },
            { path: 'label/:customLabel/:mailId', element: <MailPage /> },
            { path: ':systemLabel', element: <MailPage /> },
            { path: ':systemLabel/:mailId', element: <MailPage /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        { path: 'calendar', element: <CalendarPage /> },
        { path: 'kanban', element: <KanbanPage /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        // TODO: replace with home page once done
        // { element: <HomePage />, index: true },
        { path: '/index', element: <HomePage /> },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <FaqsPage /> },

        // Demo Components
        {
          path: 'components',
          children: [
            { element: <ComponentsOverviewPage />, index: true },
            {
              path: 'foundation',
              children: [
                { element: <Navigate to="/components/foundation/colors" replace />, index: true },
                { path: 'colors', element: <FoundationColorsPage /> },
                { path: 'typography', element: <FoundationTypographyPage /> },
                { path: 'shadows', element: <FoundationShadowsPage /> },
                { path: 'grid', element: <FoundationGridPage /> },
                { path: 'icons', element: <FoundationIconsPage /> },
              ],
            },
            {
              path: 'mui',
              children: [
                { element: <Navigate to="/components/mui/accordion" replace />, index: true },
                { path: 'accordion', element: <MUIAccordionPage /> },
                { path: 'alert', element: <MUIAlertPage /> },
                { path: 'autocomplete', element: <MUIAutocompletePage /> },
                { path: 'avatar', element: <MUIAvatarPage /> },
                { path: 'badge', element: <MUIBadgePage /> },
                { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
                { path: 'buttons', element: <MUIButtonsPage /> },
                { path: 'checkbox', element: <MUICheckboxPage /> },
                { path: 'chip', element: <MUIChipPage /> },
                { path: 'data-grid', element: <MUIDataGridPage /> },
                { path: 'dialog', element: <MUIDialogPage /> },
                { path: 'list', element: <MUIListPage /> },
                { path: 'menu', element: <MUIMenuPage /> },
                { path: 'pagination', element: <MUIPaginationPage /> },
                { path: 'pickers', element: <MUIPickersPage /> },
                { path: 'popover', element: <MUIPopoverPage /> },
                { path: 'progress', element: <MUIProgressPage /> },
                { path: 'radio-button', element: <MUIRadioButtonsPage /> },
                { path: 'rating', element: <MUIRatingPage /> },
                { path: 'slider', element: <MUISliderPage /> },
                { path: 'stepper', element: <MUIStepperPage /> },
                { path: 'switch', element: <MUISwitchPage /> },
                { path: 'table', element: <MUITablePage /> },
                { path: 'tabs', element: <MUITabsPage /> },
                { path: 'textfield', element: <MUITextFieldPage /> },
                { path: 'timeline', element: <MUITimelinePage /> },
                { path: 'tooltip', element: <MUITooltipPage /> },
                { path: 'transfer-list', element: <MUITransferListPage /> },
                { path: 'tree-view', element: <MUITreesViewPage /> },
              ],
            },
            {
              path: 'extra',
              children: [
                { element: <Navigate to="/components/extra/animate" replace />, index: true },
                { path: 'animate', element: <DemoAnimatePage /> },
                { path: 'carousel', element: <DemoCarouselsPage /> },
                { path: 'chart', element: <DemoChartsPage /> },
                { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
                { path: 'editor', element: <DemoEditorPage /> },
                { path: 'form-validation', element: <DemoFormValidationPage /> },
                { path: 'image', element: <DemoImagePage /> },
                { path: 'label', element: <DemoLabelPage /> },
                { path: 'lightbox', element: <DemoLightboxPage /> },
                { path: 'map', element: <DemoMapPage /> },
                { path: 'mega-menu', element: <DemoMegaMenuPage /> },
                { path: 'multi-language', element: <DemoMultiLanguagePage /> },
                { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
                { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
                { path: 'scroll', element: <DemoScrollbarPage /> },
                { path: 'snackbar', element: <DemoSnackbarPage /> },
                { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
                { path: 'upload', element: <DemoUploadPage /> },
                { path: 'markdown', element: <DemoMarkdownPage /> },
              ],
            },
          ],
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        // { path: '/', index: true, element: <IntroductionPage /> },
        { path: 'v1/interactive', index: true, element: <InteractivePage /> },
        { path: 'v1/preview-videos', element: <PreviewVideoPage /> },
        // { path: 'v1/', element: <IntroductionPage /> },
        { path: 'v1/partner-portal', element: <PartnerTilesPage /> },
        { path: 'v1/operator-portal', element: <OpeartorTilesPage /> },
        { path: 'v1/introduction', element: <IntroductionPage /> },
        // { path: 'v1/partner-onboarding', element: <PartnerOnboardingPage /> },
        { path: 'v1/points-burn-contract', element: <PointsBurnContractPage /> },
        { path: 'v1/reporting', element: <ReportingPage /> },
        { path: 'v1/operator/reporting', element: <OperatorReportingPage /> },
        { path: 'v1/operator/points-burn-contract', element: <OperatorBurnContract /> },
        { path: 'v1/payments-invoicing', element: <PaymentInvoicingPage /> },
        { path: 'v1/partnership-benefits', element: <PartnershipBenefitsPage /> },
        { path: 'v1/customer-service', element: <CustomerServicesPage /> },
        { path: 'v1/advertisement', element: <AdvertisementPage /> },
        { path: 'v1/promotions', element: <PromotionsPage /> },
        { path: 'v1/survey', element: <SurveyPage /> },
        { path: 'v1/partner-onboarding-detail', element: <PartnerOnboardingDetailPage /> },

        // { path: 'v2/', element: <VideoPageV2 /> },
        // { path: 'v2/operator-portal', element: <OpeartorTilesPage /> },
        // { path: 'v2/introduction', element: <VideoPage /> },
        // { path: 'v2/partner-onboarding', element: <PartnerOnboardingPage /> },

        { path: 'pricing', element: <PricingPage /> },
        { path: 'payment', element: <PaymentPage /> },
      ],
    },
    // -----------------
    {
      element: <SimpleWithoutHeader />,
      children: [
        { path: '/', index: true, element: <LandingPage /> },
        { path: 'v1/partner-onboarding', element: <PartnerOnboardingPageV3 /> },
        { path: 'get-in-touch', element: <GetInTouch /> },
      ],
    },
    // --------------------
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
