import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Box, Link } from '@mui/material';
// config
import { HEADER } from '../../config-global';
// utils
import { bgBlur } from '../../utils/cssStyles';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/logo';

// ------------------------------------wwwwwwwwww----------------------------------

Header.propTypes = {
  isOffset: PropTypes.bool,
};

export default function Header({ isOffset }) {
  const theme = useTheme();

  const navigate = useNavigate();

  let programDetailsLocal = JSON.parse(localStorage.getItem('programDetails'));

  return (
    <AppBar color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <>{/* <Logo /> */}</>
        <>
          <Box
            component="div"
            sx={{
              width: 100,
              height: 100,
              display: 'inline-flex',
            }}
          >
            <Box
              style={{ cursor: 'pointer', marginTop: '10px' }}
              onClick={() => {
                navigate('/');
              }}
            >
              {/* JSON.parse(localStorage.getItem('programDetails')).programLogoUri */}
              <img
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'contain',
                  borderRadius: '10px',
                  marginLeft: '10px',
                }}
                // src={`https://core-engine.dev.loyyal.net/program-logos/a1f94bbd-ce2c-404c-8abc-78f6654c20b0.png`}
                src={`${process.env.REACT_APP_CORE_ENGINE_URL}${programDetailsLocal?.programLogoUri}`}
              />
            </Box>
          </Box>
        </>
        {/* get-in-touch */}
        <Link component={RouterLink} to="/get-in-touch" variant="subtitle2" color="inherit">
          Get in touch
        </Link>
        {/* <Link
          component={RouterLink}
          onClick={() => {
            navigate('/get-in-touch');
          }}
          variant="subtitle2"
          color="inherit"
        >
          care@loyyal.com
        </Link> */}
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
