import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, Paper, Typography, styled } from '@mui/material';
import { LandingPageImages } from '../assets/images';
import Image from '../components/image';
import { Link } from 'react-router-dom';
import Logo from 'src/components/logo';
import { NavItem } from 'src/layouts/main/nav/desktop/NavItem';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from '../components/snackbar';

const StyledHeading = styled(Typography)({
  fontWeight: 600,
  fontSize: '5rem',
  lineHeight: '6.25rem',
});

const StyledSubHeading = styled(Typography)({
  fontWeight: 500,
  fontSize: '2rem',
  textTransform: 'uppercase',
});

const StyledDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: '1.7rem',
  marginY: '1rem',
});

const StyledCardDescription = styled(Typography)({
  fontWeight: 600,
  fontSize: '2.5rem',
  marginY: '1rem',
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: '#000000',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  minHeight: '10rem',
  borderRadius: '20px',
}));

const CircleContainer = styled(Box)({
  position: 'absolute',
  top: 30, // Adjust top padding as needed
  right: 30, // Adjust right padding as needed
  backgroundColor: 'white', // Background color of the circular card
  borderRadius: '50%',
  width: '70px',
  height: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Box shadow for the circular card
});

const NAV_ITEMS = [
  { title: 'Home', path: '/' },
  { title: 'About', path: '/' },
  { title: 'Earn Miles', path: '/' },
  { title: 'Burn Miles', path: '/' },
  { title: 'Become A Partner', path: '/' },
  { title: 'Support', path: '/' },
  { title: 'Dashboard', path: '/dashboard/features' },
];
const NAV_ITEMS1 = [
  { title: 'Home', path: '/' },
  { title: 'About', path: '/' },
  { title: 'Earn Miles', path: '/' },
  { title: 'Burn Miles', path: '/' },
  { title: 'Become A Partner', path: '/' },
  { title: 'Support', path: '/' },
];

// const url = window.location.href------------;

// params: {
//   domain: url.includes('ethiopianairlines')
//     ? 'ethiopianairlines.accesspoint.dev.loyyal.io'
//     : url.includes('enoc')
//     ? 'enoc.accesspoint.dev.loyyal.io'
//     : 'ethiopianairlines.accesspoint.dev.loyyal.io',
// }

// const init = async () => {

//   try {
//     const response = await listPointsEarnContract(
//       {
//         programDetails: {
//           programCode: decodedJwt.hasOwnProperty('programCode')
//             ? decodedJwt?.programCode
//             : selectedProgram,
//         },
//       },
//       decodedJwt,
//       selectedProgram
//     );

//     if (response?.body) {
//       let earnContracts = response.body;
//       if (contracts) {
//         earnContracts = earnContracts.sort((item1, item2) => {
//           return new Date(item2.createdOn).getTime() - new Date(item1.createdOn).getTime();
//         });
//       }
//       setContracts(earnContracts);
//     } else {
//       enqueueSnackbar('No contract request found', {
//         variant: 'error',
//       });
//     }
//   } catch (error) {
//     enqueueSnackbar('No contract request found', {
//       variant: 'error',
//     });
//   }
// };

export default function LandingPage() {
  const navigate = useNavigate();

  const location = useLocation();

  const [programDetails, setProgramDetails] = useState(null);

  const currentUrl = location.pathname;
  const url = window.location.href;
  // console.log(url);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const urlObject = new URL(url);
  console.log(urlObject.hostname, 'urlObject---------');

  const getProgramUsingDomain = async () => {
    // const url = window.location.href;

    try {
      var config = {
        method: 'get',

        url: `${process.env.REACT_APP_CORE_ENGINE_URL}/program/getProgramUsingDomain`,
        headers: {
          Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTM5NTI0MDEsImlzcyI6ImNvcmUtZW5naW5lLXNlcnZpY2UiLCJzdWIiOiJhYW1pci5tb2hkQGxveXlhbC5jb20iLCJhdWQiOlsiY29yZS1lbmdpbmUtc2VydmljZSIsImNvbnRyYWN0LW1hbmFnZW1lbnQtc2VydmljZSIsImFsbC1sb3l5YWwtZnJvbnRlbmRzIl0sImF1dGhvcml6ZWQiOnRydWUsIm5hbWUiOiJBYW1pciIsInVzZXJuYW1lIjoiYWFtaXIubW9oZEBsb3l5YWwuY29tIiwicm9sZUNvZGUiOiJMT1lZQUxfQURNSU4iLCJleHAiOjE3NDU0ODg0MDF9.BQ35nGRf2xt6YUokI6DUoZ0RmnyJPZLb4Wn2CGCu1PfQx9tFN0OtHM4cW4IQxlEfzzGE9bl3V8rNImpqVJS9uhEDF5Jey2NvJHUFUoW03rsOEHxKSyu9whMQ9ez92isqbb-T5x_ovKFjSINiETm5Kalr_u8QDaC_904enxWrIRrDECYtuBIZvN67DxoaryhEDjpaLQ_tQ1GD1P6IuFZtkaPzv1skxbv72M1r1C8_b870qwrtI2iJXIai8RvtNvyuEiU-yGMyjMDsg7lKTzl5IKM72WiVT4h5qvy3_kjnIt4133Rr-i0PcZHwpZ4LPsIWvV3gz5qTwpaO17YCKzZVIg`,
        },
        // params: {
        //   domain: 'enoc.accesspoint.dev.loyyal.io',
        // },
        params: {
          // domain: 'ba.accesspoint.staging.loyyal.io',
          // domain: 'ethiopianairlines.accesspoint.staging.loyyal.io',
          domain: urlObject.hostname,
        },
      };

      const response = await axios(config);
      setProgramDetails(response?.data);
      // console.log(response?.data);
      // localStorage.setItem('programCode', response?.data.programCode);
      localStorage.setItem('programDetails', JSON.stringify(response?.data));
    } catch (error) {
      enqueueSnackbar('No Program details found', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    getProgramUsingDomain();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#D1D1D1',
          paddingX: 10,
          paddingY: 1,
        }}
      >
        <Logo programDetails={programDetails} />

        <Box sx={{ display: 'flex', gap: 5 }}>
          {/* {NAV_ITEMS.map((item, i) => (
            <NavItem item={item} key={i} />
          ))} */}
          {url.includes('ethiopianairlines') ||
          url.includes('enoc') ||
          url.includes('staging.loyyal.io')
            ? NAV_ITEMS1.map((item, i) => <NavItem item={item} key={i} />)
            : NAV_ITEMS.map((item, i) => <NavItem item={item} key={i} />)}
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          padding="30px"
        >
          <StyledHeading>
            Become a <span style={{ color: 'blue' }}>Partner!</span>
          </StyledHeading>
          <StyledSubHeading my={2}>Let&apos;s revolutionize the loyalty landscape</StyledSubHeading>
          <StyledDescription width="55%">
            Increase your revenue with seamless collaboration! <br /> Join our partnership program
            and unlock a world of opportunities to connect with multitudes of members, add new
            customers and higher share of wallet
          </StyledDescription>
          <Image
            sx={{ borderRadius: '20px' }}
            src={LandingPageImages.becomePartnerImage}
            alt="landing page"
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="auto"
          padding="30px"
          bgcolor="#E9E9E9"
        >
          <Box flexBasis="50%">
            <Box maxWidth="90%">
              <StyledHeading mb={5}>
                Stand out from your <span style={{ color: 'blue' }}>competitors</span>
              </StyledHeading>
              <StyledDescription>
                Experience the deep impact of our loyalty program in your business to help you gain
                steps ahead of your competition. On average 23% members like to shop where they get
                rewards back. Also they bring 18% bigger share of wallet. Get benefits of our
                loyalty program in your business.
              </StyledDescription>
            </Box>
          </Box>
          <Box flexBasis="50%">
            <Image
              sx={{ borderRadius: '20px' }}
              src={LandingPageImages.standOutCompetetor}
              alt="landing page"
            />
          </Box>
        </Box>
        <Box display="flex" height="auto" padding="30px">
          <Box flexBasis="50%">
            <Image
              sx={{ borderRadius: '20px' }}
              src={LandingPageImages.partnershipImage}
              alt="landing page"
            />
          </Box>
          <Box flexBasis="50%" display="flex" justifyContent="center" alignItems="center" p={4}>
            <Box maxWidth="90%">
              <StyledHeading mb={5}>
                Partnership is as <span style={{ color: 'blue' }}>easy as 1,2,3</span>
              </StyledHeading>
              <StyledDescription>
                Discover the simplicity of collaboration with us – where partnership is as easy as
                1,2,3. Streamlined processes, seamless integration, and unmatched support make
                working together extremely simple. Your systems don&apos;t need to change and we do
                the heavy lifting.
              </StyledDescription>
            </Box>
          </Box>
        </Box>
        <Box bgcolor="#E9E9E9" py={4}>
          <StyledHeading textAlign="center">
            How it
            <span style={{ color: 'blue' }}> works</span>
          </StyledHeading>
          <StyledDescription textAlign="center">
            Discover the mechanics by understanding how the process unfolds.
          </StyledDescription>
          <Box sx={{ flexGrow: 1, padding: '2rem' }}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Item sx={{ minHeight: '16rem' }}>
                  <Box width="10%">
                    <Image
                      sx={{ borderRadius: '20px' }}
                      src={LandingPageImages.dealImage}
                      alt="landing page"
                    />
                  </Box>
                  <Box width="80%" fontSize="2.5rem">
                    <StyledCardDescription>
                      We bring the power of our brand and communicate our partnership to millions of
                      our members
                    </StyledCardDescription>
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item sx={{ minHeight: '16rem' }}>
                  <Box width="10%">
                    <Image
                      sx={{ borderRadius: '20px' }}
                      src={LandingPageImages.increaseIcon}
                      alt="landing page"
                    />
                  </Box>
                  <Box width="80%">
                    <StyledCardDescription>
                      You bring the affiliate commission only on actual sales on your business.{' '}
                    </StyledCardDescription>
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <Box width="10%">
                    <Image
                      sx={{ borderRadius: '20px' }}
                      src={LandingPageImages.cardsIcon}
                      alt="landing page"
                    />
                  </Box>
                  <Box width="80%">
                    <StyledCardDescription>We credit miles to the member.</StyledCardDescription>
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ minHeight: '16rem' }}>
                <Item>
                  <Box width="10%">
                    <Image
                      sx={{ borderRadius: '20px' }}
                      src={LandingPageImages.repeatIcon}
                      alt="landing page"
                    />
                  </Box>
                  <Box width="80%">
                    <StyledCardDescription>
                      Member becomes your repeat customer.
                    </StyledCardDescription>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box py={16}>
          <Box display="flex">
            <Box flexBasis="50%" px={3}>
              <StyledHeading textAlign="center">
                How to <span style={{ color: 'blue' }}>get started</span>
              </StyledHeading>
              <StyledDescription textAlign="center">
                To initiate the process, begin by taking these essential steps.
              </StyledDescription>
              <Box py={6} px={8}>
                <Image
                  sx={{ borderRadius: '50%' }}
                  src={LandingPageImages.getStartedImage}
                  alt="landing page"
                />
              </Box>
            </Box>
            <Grid flexBasis="50%" container spacing={4}>
              <Grid item xs={6}>
                <Card sx={{ p: 4, height: '100%' }}>
                  <Box width="20%" mt={8}>
                    <Image src={LandingPageImages.detailsIcon} alt="landing page" />
                  </Box>
                  <Box width="70%" fontSize="2.5rem">
                    <StyledCardDescription>Provide your business details</StyledCardDescription>
                  </Box>
                  <CircleContainer>
                    <StyledCardDescription>01</StyledCardDescription>
                  </CircleContainer>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={{ p: 4, height: '100%' }}>
                  <Box width="20%" mt={8}>
                    <Image src={LandingPageImages.smartContractIcon} alt="landing page" />
                  </Box>
                  <Box width="80%">
                    <StyledCardDescription>Sign an automated smart contract</StyledCardDescription>
                  </Box>
                  <CircleContainer>
                    <StyledCardDescription>02</StyledCardDescription>
                  </CircleContainer>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={{ p: 4, height: '100%' }}>
                  <Box width="20%" mt={8}>
                    <Image src={LandingPageImages.verifiedDocumentIcon} alt="landing page" />
                  </Box>
                  <Box width="80%">
                    <StyledCardDescription>Program approves the request</StyledCardDescription>
                  </Box>
                  <CircleContainer>
                    <StyledCardDescription>03</StyledCardDescription>
                  </CircleContainer>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={{ p: 4, height: '100%' }}>
                  <Box width="20%" mt={8}>
                    <Image src={LandingPageImages.newCustomerIcon} alt="landing page" />
                  </Box>
                  <Box width="80%">
                    <StyledCardDescription>Start getting new customers</StyledCardDescription>
                  </Box>
                  <CircleContainer>
                    <StyledCardDescription>04</StyledCardDescription>
                  </CircleContainer>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" my={16} justifyContent="center">
            <Box px={16}>
              <Box
                bgcolor="#E9E9E9"
                borderRadius="20px"
                mb={4}
                p={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box width="20%">
                  <Image src={LandingPageImages.etailerImage} alt="landing page" />
                </Box>
                <StyledCardDescription sx={{ fontWeight: 600 }} textAlign="center">
                  E-tailers:
                </StyledCardDescription>
                <Typography textAlign="center">
                  Bring your affiliate network or become part of our network.
                </Typography>
              </Box>
              <Box
                bgcolor="#E9E9E9"
                borderRadius="20px"
                p={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box width="20%">
                  <Image src={LandingPageImages.retailerImage} alt="landing page" />
                </Box>
                <StyledCardDescription sx={{ fontWeight: 600 }} textAlign="center">
                  Retailers:
                </StyledCardDescription>
                <Typography textAlign="center">
                  Install a small application in your POS system for recording transactions.
                </Typography>
              </Box>
            </Box>
            <Box px={16}>
              <Image src={LandingPageImages.etailerRetailerImage} alt="landing page" />
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1, padding: '4rem' }}>
            <Grid container spacing={4}>
              <Grid item xs={6} sx={{ position: 'relative' }}>
                <Item>
                  <Box width="100%">
                    <Image
                      sx={{ borderRadius: '20px' }}
                      src={LandingPageImages.getInTouchImage}
                      alt="landing page"
                    />
                    <Box sx={{ position: 'absolute', bottom: '4rem', left: '0', right: '0' }}>
                      <Button
                        href="/get-in-touch"
                        variant="contained"
                        sx={{
                          width: '50%',
                          display: 'block',
                          textAlign: 'center',
                          margin: 'auto',
                          marginBottom: '20px',
                        }}
                      >
                        Get in touch!
                      </Button>
                      <StyledCardDescription
                        sx={{
                          fontSize: '12px',
                          paddingLeft: '5rem',
                          paddingRight: '5rem',
                          textAlign: 'center',
                        }}
                      >
                        Reach out to us for personalized assistance and seamless collaboration. Your
                        connection to success starts with a simple touch – get in touch today.
                      </StyledCardDescription>
                    </Box>
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ position: 'relative' }}>
                <Item>
                  <Box width="100%">
                    <Image
                      sx={{ borderRadius: '20px' }}
                      src={LandingPageImages.getStartedBgImage}
                      alt="landing page"
                    />
                    <Box sx={{ position: 'absolute', bottom: '4rem', left: '0', right: '0' }}>
                      <Button
                        //   href='/nda-page'
                        onClick={() => {
                          navigate('/v1/partner-onboarding');
                        }}
                        variant="contained"
                        disabled={!programDetails}
                        sx={{
                          width: '50%',
                          display: 'block',
                          textAlign: 'center',
                          margin: 'auto',
                          marginBottom: '20px',
                        }}
                      >
                        Get Started!
                      </Button>

                      <StyledCardDescription
                        sx={{
                          fontSize: '12px',
                          paddingLeft: '5rem',
                          paddingRight: '5rem',
                          textAlign: 'center',
                        }}
                      >
                        Embark on your journey by initiating the onboarding process and stepping
                        into a partnership with us. Begin the path to collaboration and growth
                        today.
                      </StyledCardDescription>
                    </Box>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
