// useSessionStorage.js
import { useState, useEffect } from 'react';

function useSessionStorage(key, initialValue) {
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue !== null ? storedValue : initialValue;
  });

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === key) {
        setValue(event.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  const setSessionStorageValue = (newValue) => {
    setValue(newValue);
    sessionStorage.setItem(key, newValue);
  };

  return [value, setSessionStorageValue];
}

export default useSessionStorage;
