import { m } from 'framer-motion';
import {
  Button,
  Typography,
  TextField,
  Stack,
  Container,
  Grid,
  Stepper,
  StepLabel,
  Box,
  Step,
  Paper,
  StepContent,
  MenuItem,
  CardHeader,
  CardContent,
  Tooltip,
  Card,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  getSalesList,
  getVolumePenetrationList,
  getVolumePropositionList,
  getGrowthList,
  getDefaultTargetVolumeConfiguration,
} from 'src/utils/chart_calculation_ver1';
import { varFade, MotionViewport } from '../../components/animate';
import { ChartColumnSingle1 } from '../_examples/extra/chart';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { TableHeadCustom } from 'src/components/table';
import InputAdornment from '@mui/material/InputAdornment';

import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export function CalculateProjectedVolume({ volume, handleChange, programUsingDomain }) {
  const [sales, setSales] = useState([]);
  const [penetration, setPenetration] = useState([]);
  const [growth, setGrowth] = useState([]);
  const [proposition, setProposition] = useState([]);
  const [calculatedProposition, setCalculatedProposition] = useState(0);

  let USD = 1;
  let ffCommission = 0;
  let llCommision = 30;
  let cashbackUserInput = volume?.cashbackPer;
  const decimalPercentage = cashbackUserInput / 100;
  let commissionInUsd = USD * decimalPercentage;
  console.log(commissionInUsd, 'commissionInUsd');
  let memberProgramCommision = (commissionInUsd * 0.7).toFixed(3);
  let cppMemberUSD = programUsingDomain?.costPerPoint;
  // let propositionCalculated = `${Math.round(memberProgramCommision/cppMemberUSD)} points/USD`
  let propositionCalculated = Math.round(memberProgramCommision / cppMemberUSD);
  // console.log(propositionCalculated,"propositionCalculated")

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await getDefaultTargetVolumeConfiguration();

    console.log(response, 'response');

    setSales(response.body[0].config.sales);
    setProposition(response.body[0].config.proposition);
    setPenetration(response.body[0].config.penetration);
    setGrowth(response.body[0].config.growth);
  };

  console.log(sales, 'sales====');
  return (
    <>
      <Stack style={{ padding: '40px' }} component={MotionViewport} spacing={3}>
        <Typography variant="h4" style={{ textAlign: 'left' }}>
          Calculate Projected Volume
        </Typography>
        <Grid container spacing={2} style={{ justifyContent: 'space-evenly' }}>
          <Grid item xs={12} md={2}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // border: '1px solid ',
                marginBottom: '10px',
              }}
            >
              <Typography variant="h6">Sales</Typography>
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'rgb(134, 133, 133)', // Change this to your desired background color
                      color: 'white', // Change this to your desired text color
                      width: '200px',
                    },
                  },
                }}
                title={'Total annual sales for the proposition eligible for loyalty rewards?'}
                arrow
              >
                <InfoIcon
                  style={{ fontSize: '15px', marginLeft: '7px', color: 'rgb(134, 133, 133)' }}
                />
              </Tooltip>
            </Box>
            <TextField
              size="small"
              variant="outlined"
              select
              fullWidth
              label="Sales ($)"
              value={volume.sales}
              id="volume.sales"
              onChange={(e) => handleChange(e, 'volume', 'sales')}
            >
              {/* {sales.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.name}
                </MenuItem>
              ))} */}
              {sales.map((item) => (
                // <MenuItem key={item.key} value={item.key}>
                //   {item.name}
                // </MenuItem>
                <MenuItem key={item.key} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            {/* <m.div
              style={{ background: '#868585', color: 'white', padding: '10px', margin: '10px' }}
            >
              <Typography variant="caption" style={{ marginBottom: '10px' }}>
                Total annual sales for the proposition eligible for loyalty rewards?
              </Typography>
            </m.div> */}
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // border: '1px solid ',
                marginBottom: '10px',
              }}
            >
              <Typography variant="h6">Penetration</Typography>
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'rgb(134, 133, 133)', // Change this to your desired background color
                      color: 'white', // Change this to your desired text color
                      width: '200px',
                    },
                  },
                }}
                title={'What penetration of sales do you expect loyalty to reach in a mature year?'}
                arrow
              >
                <InfoIcon
                  style={{ fontSize: '15px', marginLeft: '7px', color: 'rgb(134, 133, 133)' }}
                />
              </Tooltip>
            </Box>
            {/* <Typography variant="h6" sx={{ mb: '10px' }}>
              Penetration{' '}
            </Typography> */}
            <TextField
              size="small"
              variant="outlined"
              select
              fullWidth
              label="Penetration (%)"
              value={volume.penetration}
              id="volume.penetration"
              onChange={(e) => handleChange(e, 'volume', 'penetration')}
            >
              {penetration.map((item) => (
                // <MenuItem key={item.key} value={item.key}>
                //   {item.name}
                // </MenuItem>
                <MenuItem key={item.key} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            {/* <m.div
              style={{ background: '#868585', color: 'white', padding: '10px', margin: '10px' }}
            >
              <Typography variant="caption" style={{ marginBottom: '10px' }}>
                What penetration of sales do you expect loyalty to reach in a mature year?
              </Typography>
            </m.div> */}
          </Grid>
          {/* ------------------------------ */}
          <Grid item xs={12} md={2}>
            {/* <Typography variant="h6" sx={{ mb: '10px' }}>
              Cashback
            </Typography> */}
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // border: '1px solid ',
                marginBottom: '10px',
              }}
            >
              <Typography variant="h6">Cashback</Typography>
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'rgb(134, 133, 133)', // Change this to your desired background color
                      color: 'white', // Change this to your desired text color
                      width: '200px',
                    },
                  },
                }}
                title={'What is cashback % that you can provide.'}
                arrow
              >
                <InfoIcon
                  style={{ fontSize: '15px', marginLeft: '7px', color: 'rgb(134, 133, 133)' }}
                />
              </Tooltip>
            </Box>
            {/* <TextField size='small' variant="outlined" select fullWidth label="Cashback (%)" value={volume.cashbackPer} id="volume.cashbackPer" onChange={(e) => handleChange(e, "volume", "cashbackPer")} >
                            {penetration.map((item) => (
                                <MenuItem key={item.key} value={item.key}>{item.name}</MenuItem>
                            ))}
                        </TextField> */}
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              label="Cashback (%)"
              value={volume.cashbackPer}
              id="volume.cashbackPer"
              onChange={(e) => handleChange(e, 'volume', 'cashbackPer')}
              type="number"
            />
            {/* <m.div
              style={{ background: '#868585', color: 'white', padding: '10px', margin: '10px' }}
            >
              <Typography variant="caption" style={{ marginBottom: '10px' }}>
               
                What is cashback % that you can provide.
              </Typography>
            </m.div> */}
          </Grid>
          {/* ----------------------------- */}
          <Grid item xs={12} md={2}>
            {/* <Typography variant="h6" sx={{ mb: '10px' }}>
              Proposition
            </Typography> */}
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // border: '1px solid ',
                marginBottom: '10px',
              }}
            >
              <Typography variant="h6">Proposition</Typography>
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'rgb(134, 133, 133)', // Change this to your desired background color
                      color: 'white', // Change this to your desired text color
                      width: '200px',
                    },
                  },
                }}
                title={'Based on cashback, this is the member proposition.'}
                arrow
              >
                <InfoIcon
                  style={{ fontSize: '15px', marginLeft: '7px', color: 'rgb(134, 133, 133)' }}
                />
              </Tooltip>
            </Box>
            {/* <TextField size='small' variant="outlined" select fullWidth label="Proposition" value={volume.proposition} id="volume.proposition" onChange={(e) => handleChange(e, "volume", "proposition")} >
                            {proposition.map((item) => (
                                <MenuItem key={item.key} value={item.key}>{item.name}</MenuItem>
                            ))}
                        </TextField> */}
            <TextField
              size="small"
              disabled
              variant="outlined"
              fullWidth
              label="Proposition"
              value={propositionCalculated}
              id="volume.proposition"
              onChange={(e) => handleChange(e, 'volume', 'proposition')}
              InputProps={{
                endAdornment: <InputAdornment position="end">points/USD</InputAdornment>,
              }}
            />

            {/* <m.div
              style={{ background: '#868585', color: 'white', padding: '10px', margin: '10px' }}
            >
              <Typography variant="caption" style={{ marginBottom: '10px' }}>
                
                Based on cashback, this is the member proposition.
              </Typography>
            </m.div> */}
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // border: '1px solid ',
                marginBottom: '10px',
              }}
            >
              <Typography variant="h6">Growth</Typography>
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'rgb(134, 133, 133)', // Change this to your desired background color
                      color: 'white', // Change this to your desired text color
                      width: '200px',
                    },
                  },
                }}
                title={
                  ' What % of mature year volume do you expect to reach in the first 12 months?'
                }
                arrow
              >
                <InfoIcon
                  style={{ fontSize: '15px', marginLeft: '7px', color: 'rgb(134, 133, 133)' }}
                />
              </Tooltip>
            </Box>
            {/* <Typography variant="h6" sx={{ mb: '10px' }}>
              Growth
            </Typography> */}
            <TextField
              size="small"
              variant="outlined"
              select
              fullWidth
              label="Growth (% in first year)"
              value={volume.growth}
              id="volume.growth"
              onChange={(e) => handleChange(e, 'volume', 'growth')}
            >
              {growth.map((item) => (
                <MenuItem key={item.key} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            {/* <m.div
              style={{ background: '#868585', color: 'white', padding: '10px', margin: '10px' }}
            >
              <Typography variant="caption" style={{ marginBottom: '10px' }}>
                What % of mature year volume do you expect to reach in the first 12 months?
              </Typography>
            </m.div> */}
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Card dir="ltr">
              {/* <CardHeader style={{ color: '#11c085' }} title={`${volume.annualPointsPotential.toLocaleString('en-US')} points/year`} /> */}
              <Typography
                variant="h4"
                style={{ color: 'red', marginBottom: '20px', padding: '10px' }}
              >
                {volume.annualPointsPotential.toLocaleString('en-US')} points/year
              </Typography>
              <CardContent>
                <ChartColumnSingle1
                  xAxisLabel="Years"
                  yAxisLabel="Points Volume"
                  unit="USD"
                  categories={[
                    'Year1 Q1',
                    'Year1 Q2',
                    'Year1 Q3',
                    'Year1 Q4',
                    'Year2 Q1',
                    'Year2 Q2',
                    'Year2 Q3',
                    'Year2 Q4',
                    'Year3 Q1',
                    'Year3 Q2',
                    'Year3 Q3',
                    'Year3 Q4',
                    'Year4 Q1',
                    'Year4 Q2',
                    'Year4 Q3',
                    'Year4 Q4',
                  ]}
                  series={[
                    {
                      name: 'Projected Volume',
                      type: 'column',
                      data: volume.series,
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
