import {
	Button, Typography, TextField, Stack, Container, Grid,
	Stepper,
	StepLabel,
	Box,
	Step,
	Paper,
	StepContent,
	MenuItem,
	CardHeader,
	CardContent,
	Tooltip,
	Card,
	Checkbox,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { calculateFourYearCost, getContractThreshold, getBonusIssuedPerYear, calculateBasePrice, getCollectAtOtherPartnerList, getCustomerPerYear, getExclusivityList, getMinimumCommitment, getNewToTheProgramList, getNoticePeriodList, getPaymentList, getDefaultTargetPricingConfiguration } from 'src/utils/chart_calculation_ver1';
import { varFade, MotionViewport } from '../../components/animate';
import { ChartColumnSingle1 } from '../_examples/extra/chart';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { TableHeadCustom } from 'src/components/table';
export function CalculateTargetPrice({ volume, price, handleChange }) {

	const [bonusIssued, setBonusIssued] = useState([]);
	const [minimumCommitment, setMinimumCommitment] = useState([]);
	const [notice, setNotice] = useState([]);
	const [exclusivity, setExclusivity] = useState([]);
	const [payment, setPayment] = useState([]);
	const [customerPerYear, setCustomerPerYear] = useState([]);
	const [newToProgram, setNewToProgram] = useState([]);
	const [collectAtOther, setCollectAtOther] = useState([]);

	useEffect(() => {
		init();

	}, [])

	const init = async () => {
		const response = await getDefaultTargetPricingConfiguration();

		// console.log(response.body[0].config.commitment.notice,"response.body[0].config.commitment.notice")

		setBonusIssued(response.body[0].config.proposition.bonus_issued);
		setMinimumCommitment(response.body[0].config.commitment.minimum_commitment);
		setNotice(response.body[0].config.commitment.notice);
		setExclusivity(response.body[0].config.commitment.exclusivity);
		setPayment(response.body[0].config.payment_terms.payment);
		setCustomerPerYear(response.body[0].config.recruitment.customer_per_year);
		setNewToProgram(response.body[0].config.recruitment.new_to_program);
		setCollectAtOther(response.body[0].config.recruitment.collect_at_other);
	}

	return (
		<>
			<Stack style={{ padding: "40px" }} component={MotionViewport} spacing={3}>
				<Typography variant='h4' style={{ textAlign: 'left' }} >Calculate Targeted Price</Typography>
				<Grid container spacing={3}>

					<Grid item xs={12} md={3}>
						<Typography sx={{ my: 1 }} variant='h6'>Proposition</Typography>
						<TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Bonus issued per year (%)" value={price.bonusIssuedPerYear} id="price.bonusIssuedPerYear" onChange={(e) => handleChange(e, "price", "bonusIssuedPerYear")} >
							{bonusIssued.map((item) => (
								<MenuItem key={item.value} value={item.key}>{item.name}</MenuItem>
							))}
						</TextField>
					</Grid><Grid item xs={12} md={3}>
						<Typography sx={{ my: 1 }} variant='h6'>Commitment</Typography>
						<TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Minimum Commitment (%)" value={price.minimumCommitment} id="price.minimumCommitment" onChange={(e) => handleChange(e, "price", "minimumCommitment")} >
							{minimumCommitment.map((item) => (
								<MenuItem key={item.value} value={item.key}>{item.name}</MenuItem>
							))}
						</TextField>
						<TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Notice" value={price.notice} id="price.notice" onChange={(e) => handleChange(e, "price", "notice")} >
							{notice.map((item) => (
								<MenuItem key={item.value} value={item.key}>{item.name}</MenuItem>
							))}
						</TextField>
						<TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Exclusivity" value={price.exclusivity} id="price.exclusivity" onChange={(e) => handleChange(e, "price", "exclusivity")} >
							{exclusivity.map((item) => (
								<MenuItem key={item.value} value={item.key}>{item.name}</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} md={3}>
						<Typography sx={{ my: 1 }} variant='h6'>Payment Terms</Typography>
						<TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Payment" value={price.payment} id="price.payment" onChange={(e) => handleChange(e, "price", "payment")} >
							{payment.map((item) => (
								<MenuItem key={item.value} value={item.key}>{item.name}</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} md={3}>
						<Typography sx={{ my: 1 }} variant='h6'>Recruitment</Typography>
						<TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Customers per year" value={price.customerPerYear} id="price.customerPerYear" onChange={(e) => handleChange(e, "price", "customerPerYear")} >
							{customerPerYear.map((item) => (
								<MenuItem key={item.value} value={item.key}>{item.name}</MenuItem>
							))}
						</TextField>
						<TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="New to the program (%)" value={price.percentageNewToTheProgram} id="price.percentageNewToTheProgram" onChange={(e) => handleChange(e, "price", "percentageNewToTheProgram")} >
							{newToProgram.map((item) => (
								<MenuItem key={item.value} value={item.key}>{item.name}</MenuItem>
							))}
						</TextField>
						<TextField size='small' sx={{ my: 1 }} variant="outlined" select fullWidth label="Collect at other partners (%)" value={price.percentageCollectAtOthers} id="price.percentageCollectAtOthers" onChange={(e) => handleChange(e, "price", "percentageCollectAtOthers")} >
							{collectAtOther.map((item) => (
								<MenuItem key={item.value} value={item.key}>{item.name}</MenuItem>
							))}
						</TextField>
						<TextField size='small' fullWidth label="Annual Marketing Contribution ($)" value={getCustomerPerYear()[price.customerPerYear].value * price.percentageNewToTheProgram * 2 + (getCustomerPerYear()[price.customerPerYear].value * price.percentageNewToTheProgram * price.percentageCollectAtOthers)} disabled id="volume.annualLoyaltySales" />
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12} lg={12}>
						<Card dir="ltr">
							<br />
							<Grid container>
								<Grid md={6} lg={6}>
									<Typography variant='h4' style={{ color: 'red', marginBottom: '20px', padding: '10px' }}>Four Year Cost: ${(Number(price.fourYearCost.toFixed(0))).toLocaleString('en-US')}</Typography>
								</Grid>
								<Grid md={6} lg={6}>
									<Typography variant='h4' style={{ color: 'red', marginBottom: '20px', float: 'right', padding: '10px' }}>Target Price ${price.targetPrice.toFixed(4)}</Typography>
								</Grid>
							</Grid>

							<CardContent>
								<ChartColumnSingle1
									xAxisLabel="Years"
									yAxisLabel="Points Volume"
									unit="points"
									height={600}
									categories={[
										'Year1 Q1', 'Year1 Q2', 'Year1 Q3', 'Year1 Q4',
										'Year2 Q1', 'Year2 Q2', 'Year2 Q3', 'Year2 Q4',
										'Year3 Q1', 'Year3 Q2', 'Year3 Q3', 'Year3 Q4',
										'Year4 Q1', 'Year4 Q2', 'Year4 Q3', 'Year4 Q4',
									]}
									series={[
										{
											name: 'Total Points Volume',
											type: 'column',
											data: price.series,
										},
										// {
										//   name: 'Minimum Commitment',
										//   type: 'column',
										//   data: [volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10,
										//   volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10,
										//   volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10,
										//   volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10, volume.annualPointsPotential*0.10],
										// },
										{
											name: `Stretch Price: $${Number(price.tierPricing[0]).toFixed(4)} ( above ${price.tierVolume[1]} points)`,
											type: 'area',
											data: [price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0],
											price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0],
											price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0],
											price.tierVolume[0], price.tierVolume[0], price.tierVolume[0], price.tierVolume[0]]
										},
										{
											name: `<b style="color: red">Target Price: $${Number(price.tierPricing[1]).toFixed(4)}</b> (${price.tierVolume[2]} - ${price.tierVolume[1]} points)`,
											type: 'area',
											data: [price.tierVolume[1], price.tierVolume[1], price.tierVolume[1], price.tierVolume[1],
											price.tierVolume[1], price.tierVolume[1], price.tierVolume[1], price.tierVolume[1],
											price.tierVolume[1], price.tierVolume[1], price.tierVolume[1], price.tierVolume[1],
											price.tierVolume[1], price.tierVolume[1], price.tierVolume[1], price.tierVolume[1]]
										},
										{
											name: `Level Three Price: $${Number(price.tierPricing[2]).toFixed(4)} (${price.tierVolume[3]} - ${price.tierVolume[2]} points)`,
											type: 'area',
											data: [price.tierVolume[2], price.tierVolume[2], price.tierVolume[2], price.tierVolume[2],
											price.tierVolume[2], price.tierVolume[2], price.tierVolume[2], price.tierVolume[2],
											price.tierVolume[2], price.tierVolume[2], price.tierVolume[2], price.tierVolume[2],
											price.tierVolume[2], price.tierVolume[2], price.tierVolume[2], price.tierVolume[2]]
										},
										{
											name: `Level Two Price: $${Number(price.tierPricing[3]).toFixed(4)}  (${price.tierVolume[4]} - ${price.tierVolume[3]} points)`,
											type: 'area',
											data: [price.tierVolume[3], price.tierVolume[3], price.tierVolume[3], price.tierVolume[3],
											price.tierVolume[3], price.tierVolume[3], price.tierVolume[3], price.tierVolume[3],
											price.tierVolume[3], price.tierVolume[3], price.tierVolume[3], price.tierVolume[3],
											price.tierVolume[3], price.tierVolume[3], price.tierVolume[3], price.tierVolume[3]]
										},
										{
											name: `Level One Price: $${Number(price.tierPricing[4]).toFixed(4)} (${price.tierVolume[5]} - ${price.tierVolume[4]} points)`,
											type: 'area',
											data: [price.tierVolume[4], price.tierVolume[4], price.tierVolume[4], price.tierVolume[4],
											price.tierVolume[4], price.tierVolume[4], price.tierVolume[4], price.tierVolume[4],
											price.tierVolume[4], price.tierVolume[4], price.tierVolume[4], price.tierVolume[4],
											price.tierVolume[4], price.tierVolume[4], price.tierVolume[4], price.tierVolume[4]]
										},
									]} />


								<TableContainer sx={{ mt: 3, overflow: 'unset' }}>
									<Scrollbar>
										<Typography sx={{ my: '10px' }} variant='h6'>Tier Pricing Summary</Typography>
										<Table sx={{ minWidth: 800 }}>
											<TableHeadCustom headLabel={[{ id: 'name', label: 'Name' }, { id: 'volume', label: 'Volume' }, { id: 'price', label: 'Price (in USD)' }]} />

											<TableBody>
												<TableRow key={1} sx={{ background: '#FEC8D8' }}>
													<TableCell>{'Bonus Price'}</TableCell>
													<TableCell align="left">{`Above ${price.tierVolume[1]} points`}</TableCell>
													<TableCell align="left">{`$${Number(price.tierPricing[0]).toFixed(4)}`}</TableCell>
												</TableRow>
												<TableRow key={2} sx={{ background: '#FFB302' }}>
													<TableCell>{'Target Price'}</TableCell>
													<TableCell align="left">{`${price.tierVolume[2]} - ${price.tierVolume[1]} points`}</TableCell>
													<TableCell align="left">{`$${Number(price.tierPricing[1]).toFixed(4)}`}</TableCell>
												</TableRow>
												<TableRow key={3}>
													<TableCell>{'Tier Two Price'}</TableCell>
													<TableCell align="left">{`${price.tierVolume[3]} - ${price.tierVolume[2]} points`}</TableCell>
													<TableCell align="left">{`$${Number(price.tierPricing[2]).toFixed(4)}`}</TableCell>
												</TableRow>
												<TableRow key={4}>
													<TableCell>{'Tier One Price'}</TableCell>
													<TableCell align="left">{`${price.tierVolume[4]} - ${price.tierVolume[3]} points`}</TableCell>
													<TableCell align="left">{`$${Number(price.tierPricing[3]).toFixed(4)}`}</TableCell>
												</TableRow>
												<TableRow key={5}>
													<TableCell>{'Base Price'}</TableCell>
													<TableCell align="left">{`${price.tierVolume[5]} - ${price.tierVolume[4]} points`}</TableCell>
													<TableCell align="left">{`$${Number(price.tierPricing[4]).toFixed(4)}`}</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</Scrollbar>
								</TableContainer>
							</CardContent>
						</Card>


					</Grid>


				</Grid>
			</Stack>
		</>
	)
}